import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { MessageModificationState, SocketIoService } from '@portal/wen-backend-api';
import { OverlayManager } from '@portal/wen-components';
import { filter, map, switchMap } from 'rxjs';
import { MediaSettingsService } from '../../services/media/media-settings.service';
import { NativeNotificationHandler } from '../../services/native-notification/native-notification-handler';
import { DeepLinkNavigator } from '../../services/navigation/deep-link/deep-link-navigator';
import { WenNavigationHelper } from '../../services/navigation/types';
import { ChannelEventOrchestrator } from '../../services/socket-io/helpers/channel-event-orchestrator';
import { WenStorageService } from '../../services/storage/wen-storage.service';
import { RootState } from '../root/public-api';
import { selectIsFromSmartDesign } from '../smartdesign/smartdesign.selectors';
import { createMediaUploadErrorHandlerEffect, createMediaUploadResponseHandlerEffect } from './effects/media-upload-notification.effect';
import { createMessageNotificationUpdatesEffect } from './effects/message-notification-updates.effect';
import { createNativeNotificationRegistrationHandlerEffect, createNativeNotificationUnRegistrationHandlerEffect, createNewNativeNotificationHandlerEffect } from './effects/native-notification.effect';
import { Title } from '@angular/platform-browser';
import { selectTotalUnreadCount } from './notification.selectors';
import { selectActiveNetwork } from '../network/network.selectors';
import { NetworkInitializationHandler } from '../../../frame/network/tokens';

@Injectable()
export class NotificationEffects {

  private readonly newChannelMessages$ = this.socketIoService.channel.messages.pipe(
    filter((messages) => messages.length === 1),
    map((messages) => messages.filter(message => {
      const isNew = message.new !== false;
      const isDeleted = message.type === MessageModificationState.DELETED;
      return isNew && !isDeleted;
    }))
  );
  private readonly messageModifications$ = this.socketIoService.channel.messageModifications.listen;
  private readonly isFromSmartDesign$ = this.store.pipe(select(selectIsFromSmartDesign));

  private readonly mediaUploadListener$ = this.socketIoService.media.upload.listen;
  notificationUpdates$ = createMessageNotificationUpdatesEffect(
    this.actions$, this.newChannelMessages$, this.messageModifications$,
    this.isFromSmartDesign$,
    this.socketIoService, this.channelEventOrchestrator
  );
  nativeNotificationRegistrationHandlerEffect = createNativeNotificationRegistrationHandlerEffect(this.actions$, this.store,
    this.socketIoService, this.storageService);
  nativeNotificationUnRegistrationHandlerEffect = createNativeNotificationUnRegistrationHandlerEffect(this.actions$, this.store,
    this.socketIoService, this.storageService, this.nativeNotificationHandler);
  newNativeNotificationHandlerEffect = createNewNativeNotificationHandlerEffect(
    this.actions$, this.wenNavigationHelper, this.deepLinkNavigator
  );
  mediaUploadResponseHandlerEffect$ = createMediaUploadResponseHandlerEffect(
    this.actions$, this.mediaUploadListener$, this.store
  );
  mediaUploadErrorHandlerEffect$ = createMediaUploadErrorHandlerEffect(
    this.actions$, this.mediaUploadListener$, this.overlayManager, this.mediaSettingsService, this.store
  );

  constructor(
    private readonly store: Store<RootState>,
    private readonly actions$: Actions,
    private readonly socketIoService: SocketIoService,
    private readonly storageService: WenStorageService,
    private readonly wenNavigationHelper: WenNavigationHelper,
    private readonly nativeNotificationHandler: NativeNotificationHandler,
    private readonly overlayManager: OverlayManager,
    private readonly titleService: Title,
    private readonly mediaSettingsService: MediaSettingsService,
    private readonly channelEventOrchestrator: ChannelEventOrchestrator,
    private readonly deepLinkNavigator: DeepLinkNavigator,
    private readonly networkData: NetworkInitializationHandler,
  ) { }

  updateTitle$ = createEffect(() =>
    this.store.pipe(
      select(selectTotalUnreadCount),
      switchMap(totalUnreadCount =>
        this.store.pipe(
          select(selectActiveNetwork),
          map(activeNetwork => {
            const title = activeNetwork?.title || document.title;
            if (totalUnreadCount !== 0) {
              this.titleService.setTitle(`(${totalUnreadCount}) ${title}`);
            } else {
              this.titleService.setTitle(title);
            }
          })
        )
      )
    ),
    { dispatch: false }
  );
}
