import { Component, Input, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { challengeContextMenuId } from '../actions/challenge-action-menu';
import { ActionMenuItemProvider } from '../../../../core/services/util/action-menu-item.provider';
import { ContextMenuComponent, ContextMenuOverlayService } from '@portal/wen-components';
import { ChallengeContextMenuDatasource } from './challenge-context-menu-datasource';
import { combineLatest, first, map, of, Subject, switchMap, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChallengeAskAuthorAction } from '../actions/challenge-ask-author.action';
import { TranslateService } from '@ngx-translate/core';
import { GreyPalette } from '../../../directives/colorize/palette-provider';
import { ChallengeData } from './challenge-user-data-info';

@Component({
  selector: 'wen-challenge-context-menu',
  templateUrl: './challenge-context-menu.component.html',
  providers: [ChallengeContextMenuDatasource]
})
export class ChallengeContextMenuComponent implements OnDestroy {
  public challengeActions$: any;
  public challengeData: ChallengeData;
  private onDestroy$ = new Subject<void>();

  constructor(
    private actionMenuItemProvider: ActionMenuItemProvider,
    private datasource: ChallengeContextMenuDatasource,
    private overlayService: ContextMenuOverlayService,
    private viewContainerRef: ViewContainerRef,
    private translateService: TranslateService
  ) {}

  @Input() messageId: string;

  @ViewChild('contextMenuElement', { static: true }) contextMenuElement: ContextMenuComponent;

  openMenu() {
    if (!this.challengeActions$) {
      this.challengeActions$ = this.datasource.getUserProfileByMessageId(this.messageId).pipe(
        takeUntil(this.onDestroy$),
        first(),
        switchMap(userData => {
          return combineLatest([
            of(userData),
            this.actionMenuItemProvider.selectActions(challengeContextMenuId)
            ]
          );
        }),
        map(([userData, selectedActions]) => {
          this.challengeData = userData;
          selectedActions.forEach(action => {
            if (!userData.canDirectMessage && action instanceof ChallengeAskAuthorAction) {
              action.label = this.translateService.instant('CHALLENGE_ASK_AUTHOR_UNREACHABLE');
              action.isDisabled = true;
              action.iconColor = GreyPalette.GREY5;
            }
          });
          return selectedActions;
        }),
        tap(data => {
          this.overlayService.openOverlay(this.contextMenuElement, this.viewContainerRef);
        })
      );
    } else {
      this.overlayService.openOverlay(this.contextMenuElement, this.viewContainerRef);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
