import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Optional, Output } from '@angular/core';
import { WenIconModule } from '../../wen-icon/wen-icon.module';
import { OverlayVisibilityService } from '../providers/overlay-visibility.service';
import { TranslateModule } from '@ngx-translate/core';
import { TouchEndListenerModule } from '../../../directives/touch-end-listener/touch-end-listener.module';
import { Observable } from 'rxjs';

@Component({
  selector: 'wen-lightbox-header',
  templateUrl: './lightbox-header.component.html',
  styleUrls: ['./lightbox-header.component.scss'],
  imports: [
    AsyncPipe,
    NgIf,
    TouchEndListenerModule,
    TranslateModule,
    WenIconModule,
  ],
  standalone: true
})
export class LightboxHeaderComponent implements AfterViewInit {

  private hammerManager: HammerManager;

  @Input() isFileDownloadEnabled = 'false';
  @Input() isDownloading: Observable<boolean>;
  @Output() closeEvent = new EventEmitter<void>();
  @Output() downloadEvent = new EventEmitter<void>();

  constructor(
    private elRef: ElementRef,
    @Optional() private overlayVisibilityService: OverlayVisibilityService
  ) { }

  ngAfterViewInit() {
    this.hammerManager = new Hammer.Manager(this.elRef.nativeElement);
    this.hammerManager.on('hammer.input', ({ srcEvent, direction }) => {
      if (direction === Hammer.DIRECTION_HORIZONTAL || direction === Hammer.DIRECTION_NONE) {
        srcEvent.preventDefault();
        srcEvent.stopPropagation();
        this.overlayVisibilityService.childEvent$.next(-1);
      }
    });
  }

  close(): void {
    this.closeEvent.emit();
  }

  download(): void {
    this.downloadEvent.emit();
  }
}
