<div portal-title class="poll-question">{{ pollModel.question }}</div>
<ng-container *ngIf="userAlreadyChose; else editableView">
  <ng-container [ngTemplateOutlet]="readonlyView"></ng-container>
</ng-container>

<ng-template #readonlyView>
  <wen-read-only-poll [poll]="pollModel"></wen-read-only-poll>
  <div class="wen-poll-choice-counter">
    {{ pollModel.chooserCount }} {{'VOTE_LABEL' | translate: {count: pollModel.chooserCount} }}
  </div>
</ng-template>

<ng-template #editableView>
  <wen-selection-list
    class="wen-selection-list with-text-wrap-preserve-whitespace"
    (selectionChanged)="onSelectionChange($event)"
    [selectionItems]="pollModel.options"
    [selectionModel]="selectionModel"
  ></wen-selection-list>
  <div class="wen-poll-save-button">
    <span (click)="sendPollSelection()">{{
      "VOTE_ACTION_LABEL" | translate
    }}</span>
  </div>
</ng-template>
