<div class="wen-chat-view-desktop-header-content" *ngIf="roomHeader$ | async; let roomHeader" (click)="openDetail()">
  <wen-active-item-image-view></wen-active-item-image-view>
  <div class="wen-chat-view-desktop-header-info">
    <div class="wen-chat-view-desktop-header-info-title">
      <div portal-title>{{ roomHeader.title }}</div>
      <wen-icon class="wen-icon-small" *ngIf="roomHeader.isMuted" [iconName]="'mute'" [color]="'#767676'"></wen-icon>
    </div>
    <div portal-body-2>{{ roomHeader?.members }}</div>
  </div>
</div>
