
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { of } from 'rxjs';
import { MessageDeepLinkable } from '../../../../../core/services/navigation/deep-link/deep-linkables/message-deep-linkable';
import { ShareService } from '../../../../channel-specific/providers/share.service';
import { MessageBoxModel } from '../../../feed/components/we-feed/we-feed-model';

@Injectable()
export class ChannelMessageBoxShareAction implements ActionMenuItem {

  label: string;
  icon = 'share';

  constructor(
    private translateService: TranslateService,
    private shareService: ShareService,
    private deepLinkHandler: MessageDeepLinkable
  ) {
    this.label = this.translateService.instant('MESSAGE_BOX_CONTEXT_MENU_LABEL_SHARE');
  }

  isVisible$ = (data: MessageBoxModel) => {
    return of(Boolean(data) && !data.scheduled && !data.isDraft);
  };

  execute(data: MessageBoxModel) {
    this.shareService.shareDeepLink(this.deepLinkHandler.getDeepLink({ messageId: data.messageId }));
  }

}
