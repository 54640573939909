import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChannelListItemModel, ListItemDescription } from '../models/list-item-model';
import { ChannelInviteState, isInviteToChannelState, isJoinRequestChannelState } from '@portal/wen-backend-api';
import { getMediaTypeData } from '@portal/wen-components';
import { lastItem } from '../../../../../../../core/common/operators/array-utils';
import { isLinkEmbed, isDocumentEmbed, isPollEmbed, isMediaEmbed, isNotificationEmbed } from '../../../../../../../core/store/channel/util/embed-type-helper';
import { ChannelInvitePropertiesForListItem, ChannelListItemProperties, ChannelMessagePropertiesForListItem } from './types';

@Injectable()
export class ChannelListItemFactory {
  constructor(
    private readonly translateService: TranslateService,
  ) { }

  createSimpleListItem(props: ChannelListItemProperties): ChannelListItemModel {
    const { channel, subscribed } = props;

    const model: ChannelListItemModel = {
      id: channel.id,
      imageUrl: channel.icon,
      title: channel.title,
      description: this.createDescriptionFromLatestMessage(channel.description),
      restricted: channel.restricted,
      isPrivate: channel.isPrivate,
      geo: channel.geo,
      subscribed,
      lockIcon: !subscribed && !channel.invite && channel.isPrivate ? 'lock' : null,
      readingContent: channel.readingContent
    };

    return model;
  }

  createInvitationListItem(props: ChannelListItemProperties, showSubtitle: boolean): ChannelListItemModel {
    const { channel } = props;

    const model: ChannelListItemModel = {
      id: channel.id,
      imageUrl: channel.icon,
      title: channel.title,
      timestamp: channel.invite.updateTimestamp,
      description: showSubtitle ?
        this.createDescriptionFromLatestMessage(channel.description) :
        this.createDescriptionFromInviteState(channel.description, channel.invite),
      restricted: channel.restricted,
      isPrivate: channel.isPrivate,
      geo: channel.geo,
      pendingJoinRequest: showSubtitle && isJoinRequestChannelState(channel.invite),
      notifications: isInviteToChannelState(channel.invite) ? { badgeStyle: true } : null,
      subscribed: false,
      readingContent: channel.readingContent
    };

    return model;
  }

  createSubscribedListItem(props: ChannelListItemProperties): ChannelListItemModel {
    const { channel, subscribed, pinTimestamp, mostRecentMessage, notificationCount } = props;

    const model: ChannelListItemModel = {
      id: channel.id,
      imageUrl: channel.icon,
      title: channel.title,
      description: this.createDescriptionFromLatestMessage(mostRecentMessage || channel.description),
      notifications: {
        count: notificationCount,
        isMuted: channel.isMuted
      },
      isPrivate: channel.isPrivate,
      restricted: channel.restricted,
      geo: channel.geo,
      muteIcon: channel.isMuted ? 'mute' : null,
      lockIcon: !subscribed && !channel.invite && channel.isPrivate ? 'lock' : null,
      timestamp: mostRecentMessage?.timestamp,
      pinTimestamp,
      pendingJoinRequest: false,
      subscribed: false,
      readingContent: channel.readingContent
    };

    return model;
  }

  private createDescriptionFromLatestMessage(message: ChannelMessagePropertiesForListItem | string): ListItemDescription {
    if (typeof message === 'string') {
      return { text: message };
    }
    if (!message.content && message.embeds?.length) {
      const lastEmbed = lastItem(message.embeds);
      if (isLinkEmbed(lastEmbed)) {
        return { text: lastEmbed.title };
      }
      if (isDocumentEmbed(lastEmbed)) {
        return { text: lastEmbed.title };
      }
      if (isPollEmbed(lastEmbed)) {
        return { text: 'POLL_LABEL', icon: 'chart' };
      }
      if (isMediaEmbed(lastEmbed)) {
        const { icon, title } = getMediaTypeData(lastEmbed.subType);
        return {
          text: title, icon
        };
      }
      if (isNotificationEmbed(lastEmbed)) {
        return {
          text: lastEmbed.notificationData.message
        };
      }
    }
    return { text: message.content };
  }

  private createDescriptionFromInviteState(defaultDescription: string, invite: ChannelInvitePropertiesForListItem): ListItemDescription {
    let text: string;
    switch (invite.state) {
      case ChannelInviteState.DENIED:
        text = this.translateService.instant('JOIN_REQUEST_DENIED_STATUS_LABEL');
        break;
      case ChannelInviteState.REQUESTED:
        text = this.translateService.instant('JOIN_REQUEST_SENT_STATUS_LABEL');
        break;
      case ChannelInviteState.INVITED:
        const { insertUserName } = invite;
        text = this.translateService.instant('INVITE_CHANNEL_REQUEST_INVITED_STATUS_LABEL', { name: insertUserName });
        break;
      default:
        text = defaultDescription;
        break;
    }
    return {
      text
    };
  }

}
