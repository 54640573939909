<ng-container *ngIf="loadResult$ | async; let loadResult">
  <ng-container *ngIf="loadResult.data; let apps">
    <ng-container *ngIf="loadResult.data.length; else emptyList">
      <div class="wen-user-app-list">
        <wen-app-list (appClicked)="onAppClick($event)" [apps]="apps" [enableContextMenu]="true"></wen-app-list>
      </div>
    </ng-container>
    <ng-template #emptyList>
      <wen-empty-list
        *ngIf="loadResult.mode === 'normal'; else searchMode"
        src="/assets/wen-widget/image/empty-app-list-background.svg"
        titleLabel="APPS_PLACEHOLDER_HEADER"
        textLabel="APPS_PLACEHOLDER_MESSAGE"
        [wen-test-id]="'wen-user-empty-list'"
      ></wen-empty-list>
    </ng-template>
    <ng-template #searchMode>
      <wen-empty-search-result></wen-empty-search-result>
    </ng-template>
  </ng-container>
</ng-container>
