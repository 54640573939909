<wen-list-item *ngIf="event?.id" [showAfter]="true" [selected]="isSelected$ | async" [wen-test-id]="'event-list-item'">
  <wen-circle-image-view
    wen-list-item-icon
    [imageUrl]="event.imageSmall"
    [shape]="'square'"
    [placeholderText]="event.caption"
  ></wen-circle-image-view>
  <span wen-list-item-title>{{ event.caption }}</span>
  <span wen-list-item-description class="wen-event-list-item-description">
    <wen-icon [iconName]="'event_checkmark'" class="wen-icon-tiny"></wen-icon>
    <span class="wen-list-item-description">{{ event.start | eventDate: event?.end }}</span>
  </span>
  <span wen-list-item-subtitle class="wen-event-list-item-subtitle-location" *ngIf="location">
    <wen-icon [iconName]="'location'" class="wen-icon-tiny"></wen-icon>
    <span class="wen-event-list-item-subtitle-text wen-list-item-subtitle wen-event-list-item-location">{{ location }}</span>
    <span *ngIf="event?.distance" [wen-test-id]="'distance-label'" class="wen-event-list-item-subtitle-text wen-list-item-subtitle wen-event-list-item-distance" with-pipe-before>
      {{ event?.distance | locationDistanceFormatter }}
    </span>
  </span>
  <ng-container wen-list-item-after>
    <ng-content select="[eventListItemAfter]"></ng-content>
  </ng-container>
</wen-list-item>
