import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { Observable, of, Subject } from 'rxjs';
import { first, map, shareReplay, takeUntil } from 'rxjs/operators';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { CapitalizeFieldValueAdapter } from '../../../../../core/common/util/field-adapters/capitalize-field-value-adapter';
import { validCharacterCount } from '../../../../../core/common/util/text-utils';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { RootState } from '../../../../../core/store/root/public-api';
import { deleteUserProfile } from '../../../../../core/store/user/user.actions';
import { selectUserProfile } from '../../../../../core/store/user/user.selectors';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';

@Component({
  selector: 'wen-profile-edit',
  templateUrl: './profile-edit-view.component.html',
  styleUrls: ['./profile-edit-view.component.scss']
})
export class ProfileEditViewComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  maximumCharacterLength = 300;
  itemCount$: Observable<number>;
  isAboutYouFieldEnabled$: Observable<boolean>;
  @HostBinding('class.wen-profile-edit-view') className = true;
  fieldValueAdapter = new CapitalizeFieldValueAdapter();

  profileEditFormGroup = new FormGroup({
    avatarUrl: new FormControl(''),
    christianName: new FormControl('', {
      validators: [
        Validators.required
      ],
      updateOn: 'blur'
    }),
    lastName: new FormControl('', {
      validators: [
        Validators.required
      ],
      updateOn: 'blur'
    }),
    additionalInformation: new FormControl('')
  });

  fullName$: Observable<string>;

  constructor(
    private store: Store<RootState>,
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
    private formStoreService: FormStoreService,
    public  featureEnablementService: FeatureEnablementService,
  ) { }

  ngOnInit(): void {
    this.fullName$ = this.profileEditFormGroup.valueChanges.pipe(
      map(formValue => {
        const { christianName, lastName } = formValue || {};
        if (!christianName || !lastName) {
          return null;
        }
        const fullName = `${christianName} ${lastName}`;
        return fullName;
      })
    );
    this.store.pipe(
      select(selectUserProfile),
      firstExisty(),
      takeUntil(this.onDestroy$)
    ).subscribe(data => {
      this.formStoreService.initializeForm(data);
    });
    this.itemCount$ = this.profileEditFormGroup.controls.additionalInformation.valueChanges.pipe(
      map(value => validCharacterCount(value)),
      shareReplay(1)
    );

    this.isAboutYouFieldEnabled$ = of(this.featureEnablementService.featureFlagMethods.isEnableAboutMeField());
  }

  clearInput() {
    this.profileEditFormGroup.controls.additionalInformation.markAsDirty();
    this.profileEditFormGroup.controls.additionalInformation.setValue('');
  }

  deleteProfile() {
    this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant('USER_PROFILE_DELETE_PROFILE_CONFIRM_DIALOG_TITLE'),
      content: this.translateService.instant('USER_PROFILE_DELETE_PROFILE_CONFIRM_DIALOG_TEXT'),
      okLabel: this.translateService.instant('USER_PROFILE_DELETE_PROFILE_BUTTON_LABEL'),
      dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL')
    }).afterClosed().pipe(
      first(),
    ).subscribe((result) => {
      if (result?.result === 'ok') {
        this.store.dispatch(deleteUserProfile());
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
