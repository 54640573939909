import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { isEncryptedEvent, RoomEventsRelevantReplayResponse, RoomMessagesReplayPayload, SocketIoService } from '@portal/wen-backend-api';
import { distinctUntilChanged, filter, first, forkJoin, map, merge, mergeMap, switchMap, tap } from 'rxjs';
import { mapRelevantDirectionToStateFlags } from '../../../../shared/components/paginated-scrollview/replay-direction-mapper';
import { existy } from '../../../common/operators/existy';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { DecryptedEventModifier } from '../../../services/chat/decryption/decrypted-event-modifier';
import { MessageEventDecryptor } from '../../../services/chat/decryption/message-event-decryptor';
import { isRealtimeUpdate } from '../../../services/chat/message-event/message-event-helper';
import { ChatEventOrchestrator } from '../../../services/socket-io/helpers/chat-event-orchestrator';
import { WenOAuthService } from '../../../services/user-management/wen-oauth.service';
import { RootState } from '../../root/public-api';
import { bulkAction } from '../../root/root.actions';
import { fetchHistoricalMessagesForRoom } from '../actions/chat-history.actions';
import { subscribeChatUpdates } from '../chat.actions';
import { selectCurrentRoom } from '../chat.selectors';
import { retryEventDecryptionPossible } from '../key-actions';
import { ChatMessageOperations } from '../operations/chat-message-operations';
import { selectMessagesBySessionId } from '../selectors/chat-message-selectors';
import { filterRoomEvents } from '../utils/chat-room-utils';
import { uniqueArrayByKey } from '@portal/wen-common';
import { ChatMessageEmbedResolver } from '../../../services/chat/chat-message-embed-resolver';

@Injectable()
export class MessagesHistoryEffects {

  get userId() {
    return this.oAuthService.getUserData().userId;
  }

  fetchHistoricalMessagesForRoom$ = createEffect(() => this.actions$.pipe(
    ofType(fetchHistoricalMessagesForRoom),
    tap((payload) => {
      const params: RoomMessagesReplayPayload = {
        roomId: payload.roomId,
        userId: this.userId
      };
      if (payload.timestamp) {
        params.timestamp = payload.timestamp;
      }
      if (payload.direction) {
        params.direction = payload.direction;
      }
      this.socketIoService.chat.room.messagesReplay.emit(params);
    })
  ), { dispatch: false });

  prepareRelevantMessagesForCurrentRoomEffect$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    first(),
    switchMap(() => {
      const loadRelevantMessagesOnContext$ = this.store.pipe(
        select(selectCurrentRoom),
        existy(),
        filter((roomEntity) => !Boolean(roomEntity?.history)),
        map((roomEntity) => roomEntity.id),
        distinctUntilChanged(),
        mergeMap((roomId) => {
          return this.chatEventOrchestrator.ensureRelevantMessagesLoaded(roomId).pipe(
            mergeMap((relevantResponse) => {
              return this.createHistoryForRoom(relevantResponse[0]);
            })
          );
        })
      );
      const syncRelevantMessages$ = this.socketIoService.onReconnected$.pipe(
        tap(() => this.chatMessageEmbedResolver.clearCache()),
        switchMap(() => this.chatEventOrchestrator.ensureRelevantMessagesSynced().pipe(
          first()
        )),
        switchMap((relevantResponses) => {
          const decryptionsByRoom$ = relevantResponses.map((relevantResponse) => {
            return this.createHistoryForRoom(relevantResponse);
          });
          return forkJoin(decryptionsByRoom$).pipe(
            map((targetActions) => {
              return bulkAction({ targetActions });
            })
          );
        })
      );
      return merge(
        loadRelevantMessagesOnContext$, syncRelevantMessages$
      );
    }),
  ));

  onRetryEventDecryptionPossible$ = createEffect(() => this.actions$.pipe(
    ofType(retryEventDecryptionPossible),
    mergeMap(({ megolmSessionId }) => {
      return this.store.pipe(
        selectorWithParam(selectMessagesBySessionId, megolmSessionId),
        first(),
        map((eventEntity) => eventEntity?.encryptionData?.originalEvent),
        filter(isEncryptedEvent),
        switchMap((originalEvent) => {
          return this.messageEventDecryptor.decryptMessageEvents([originalEvent]).pipe(
            map((decryptedResults) => {
              const decryptedStoreEntities = this.decryptedEventModifier.toMessageEntities(decryptedResults);
              return this.chatMessageOperations.updateMany(originalEvent.roomId, decryptedStoreEntities, null);
            })
          );
        })
      );
    })
  ));

  private createHistoryForRoom(relevantResponse: RoomEventsRelevantReplayResponse) {
    const { roomId, events, moreDown, moreUp } = relevantResponse;
    const flags = mapRelevantDirectionToStateFlags(moreDown, moreUp);
    const [encryptedEvents, plainEvents] = filterRoomEvents(events);
    return this.messageEventDecryptor.decryptMessageEvents(encryptedEvents).pipe(
      map((decryptedResults) => {
        const decryptedStoreEntities = this.decryptedEventModifier.toMessageEntities(decryptedResults);
        const filtered = uniqueArrayByKey(decryptedStoreEntities, 'eventId');
        const filteredPlainEvents = plainEvents.filter(event => !isRealtimeUpdate(event));
        const plainStoreEntities = this.decryptedEventModifier.toMessageEntities(filteredPlainEvents);
        const entities = filtered.concat(plainStoreEntities);
        return this.chatMessageOperations.setAll(roomId, entities, flags);
      })
    );
  }
  constructor(
    private store: Store<RootState>,
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private oAuthService: WenOAuthService,
    private chatEventOrchestrator: ChatEventOrchestrator,
    private messageEventDecryptor: MessageEventDecryptor,
    private decryptedEventModifier: DecryptedEventModifier,
    private chatMessageOperations: ChatMessageOperations,
    private chatMessageEmbedResolver: ChatMessageEmbedResolver
  ) {
  }

}
