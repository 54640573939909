import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FocusFirstInputModule, WenIconModule, WenSeparatorModule, MarkdownTextModule, CircleImageViewModule, ColorizeModule, EmojiReactionsModule, ContextMenuTriggerDirective, ContextMenuComponent, ActionMenuModule, ACTION_MENU_ITEMS, WenTestIdModule, SwipeableComponent, TouchEndListenerModule } from '@portal/wen-components';
import { MessageSeparatorModule } from '../../directives/directives/message-separator.module';
import { IsCurrentUserModule } from '../../directives/is-current-user/is-current-user.module';
import { UserNavigationModule } from '../../directives/user-navigation/user-navigation.module';
import { CommentListComponent } from './comment-list.component';
import { CommentComponent } from './comment/comment.component';
import { OpenCommentsComponent } from './open-comments/open-comments.component';
import { QuoteModule } from '../quote/quote.module';
import { COMMENT_CONTEXT_MENU_ITEMS } from './actions/comment-action-menu';

@NgModule({
  imports: [
    CommonModule,
    IsCurrentUserModule,
    WenSeparatorModule,
    ColorizeModule,
    CircleImageViewModule,
    MessageSeparatorModule,
    WenIconModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MarkdownTextModule,
    FocusFirstInputModule,
    UserNavigationModule,
    EmojiReactionsModule,
    QuoteModule,
    PortalModule,
    ContextMenuTriggerDirective,
    ContextMenuComponent,
    ActionMenuModule,
    WenTestIdModule,
    SwipeableComponent,
    TouchEndListenerModule
  ],
  declarations: [
    CommentListComponent,
    CommentComponent,
    OpenCommentsComponent
  ],
  providers: [
    { provide: ACTION_MENU_ITEMS, useValue: COMMENT_CONTEXT_MENU_ITEMS, multi: true }
  ],
  exports: [
    CommentListComponent,
    OpenCommentsComponent,
  ]
})
export class CommentListModule { }
