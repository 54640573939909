import { Component } from '@angular/core';
import { selectUserProfile } from '../../../../../../core/store/user/user.selectors';
import { filter, first, map, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../../../core/store/root/public-api';
import { ConfirmationDialogComponent, OverlayManager, WenSnackbarOpener } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService, WalletCredentialType } from '@portal/wen-backend-api';
import { fetchWalletCredentials } from '../../../../../../core/store/wallet/wallet.actions';
import { headerCommandClicked } from '../../../../../../core/store/header/header.actions';
import { HeaderCommandType } from '../../../../../../core/store/header/models/HeaderCommand';
import { SharedModule } from '../../../../../../shared/shared.module';

@Component({
  selector: 'wen-credential-details-united-kiosk-flat',
  templateUrl: './credential-details-united-kiosk-flat.component.html',
  styleUrls: ['./credential-details-united-kiosk-flat.component.scss'],
  imports: [
    SharedModule
  ],
  standalone: true
})
export class CredentialDetailsUnitedKioskFlatComponent {

  public userName$ = this.store.select(selectUserProfile).pipe(
    first(),
    map(userProfile => userProfile.displayname)
  );

  constructor(
    private store: Store<RootState>,
    private overlayManager: OverlayManager,
    private snackBarOpener: WenSnackbarOpener,
    private translateService: TranslateService,
    private socketIoService: SocketIoService) {
  }

  public openRemoveCredentialDialog() {
    const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant('CREDENTIAL_DETAILS_REMOVE_CONNECTION_HEADER'),
      content: this.translateService.instant('CREDENTIAL_DETAILS_REMOVE_CONNECTION_CONTENT'),
      okLabel: this.translateService.instant('CREDENTIAL_DETAILS_REMOVE_CONNECTION'),
      dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL')
    });

    dialog.afterClosed().pipe(
      first(),
      filter(dialogData => dialogData?.result === 'ok'),
      switchMap(() => this.socketIoService.wallet.removeCredential.acknowledgement$(
        { credentialType: WalletCredentialType.UNITED_KIOSK_NEWS_FLAT }
      ))
    ).subscribe(response => {
      if (response.ok) {
        this.store.dispatch(fetchWalletCredentials());
        this.snackBarOpener.openNotificationSnackbar({
          notificationText: this.translateService.instant('CREDENTIAL_DETAILS_REMOVE_CONNECTION_SUCCESS'),
          notificationIcon: 'success'
        });
        this.store.dispatch(headerCommandClicked({ commandType: HeaderCommandType.BACK_NAVIGATION }));
      } else {
        this.snackBarOpener.openNotificationSnackbar({
          notificationText: this.translateService.instant('CREDENTIAL_DETAILS_REMOVE_CONNECTION_FAILED'),
          notificationIcon: 'error_robot'
        });
        console.error(response?.errorMsg);
      }
    });
  }
}
