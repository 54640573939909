<div class="wen-user-chat-list-wrapper">
  <ng-container *ngIf="chatLoadResult$ | async as loadResult">
    <ng-container *ngTemplateOutlet="loadResult?.data?.length ?
      chatList : emptyListView; context: { data: loadResult.data, mode: loadResult.mode }">
    </ng-container>
  </ng-container>
</div>

<ng-template #chatList let-data="data">
  <cdk-virtual-scroll-viewport itemSize="75" cdkScrollable scrollStateHandler>
    <wen-chat-list-item *cdkVirtualFor="let chat of data; trackBy: trackByFn" [chatItem]="chat"
      [markdownEnabled]="markdownEnabled" (click)="onClick(chat)" [@.disabled]="true"></wen-chat-list-item>
  </cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #emptyListView let-mode="mode">
  <wen-empty-list *ngIf="mode === 'normal'; else searchList" src="/assets/wen-widget/image/empty-chat-list.svg"
    titleLabel="USER_CHAT_LIST_EMPTY_TITLE" textLabel="USER_CHAT_LIST_EMPTY_TEXT"
    buttonLabel="DISCOVER_USER_TO_CHAT_WITH_BUTTON_LABEL" (buttonClicked)="navigateToDiscover()"
    [wen-test-id]="'wen-user-empty-list'"></wen-empty-list>
  <ng-template #searchList>
    <wen-empty-search-result></wen-empty-search-result>
  </ng-template>
</ng-template>