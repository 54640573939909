<wen-adaptive-background>
  <wen-dialog-layout wen-adaptive-background-content [headerGravity]="headerGravity" *ngIf="!isVerificationInProgress; else verifyProgress">
    <wen-dialog-layout-header [inline]="true" wenIosKeyboardSticky>
      <button headerLeft portal-button inlined (click)="onCancel()" tabindex="-1">
        <span portal-title>{{ "REGISTRATION_BACK_LABEL" | translate }}</span>
      </button>
      <button headerRight portal-button inlined (click)="onSubmit()">
        <span portal-title>{{ "REGISTRATION_CONTINUE_LABEL" | translate }}</span>
      </button>
    </wen-dialog-layout-header>
    <div class="wen-registration-code-verification-content" dialogLayoutContent>
      <wen-silent-logout></wen-silent-logout>
      <form wenFormStore class="wen-registration-code-verification-form" [formGroup]="registrationCodeVerificationFormGroup"
        (ngSubmit)="onSubmit()" wenFocusFirstInput>
        <div class="wen-registration-code-verification-info">
          <div class="wen-registration-code-verification-header" wen-header-big>{{
            "REGISTRATION_START_PHONE_NUMBER_LABEL" | translate }}
          </div>
          <div class="wen-registration-code-verification-description">{{ "REGISTRATION_CODE_VERIFICATION_DESCRIPTION" |
            translate: { phoneNumber: phoneNumber } }}</div>
        </div>
        <div class="wen-registration-code-verification-input-wrapper">
          <mat-form-field>
            <mat-label>{{ 'REGISTRATION_CODE_VERIFICATION_CODE_LABEL' | translate }}</mat-label>
            <input class="wen-phone-number-control-input" matInput autocomplete="off"
              [formControlName]="'verificationCodeControl'"
              [placeholder]="'REGISTRATION_CODE_VERIFICATION_CODE_PLACEHOLDER' | translate"
              wenIosKeyboardStickyTarget [type]="codeFieldType" pattern="\d*"/>
            <mat-error portal-caption *ngIf="hasError(['required', 'minlength', 'maxlength'])">{{
              "REGISTRATION_CODE_VERIFICATION_INVALID" | translate }}</mat-error>
            <mat-error portal-caption *ngIf="hasError('verifyCooldown')">{{ "REGISTRATION_CODE_VERIFICATION_COOLDOWN" |
              translate: { cooldown: (remainingResubmit$ | async) } }}</mat-error>
          </mat-form-field>
        </div>
        <wen-registration-code-resend [disabled]="resendDisabled$ | async" [remaining]="remainingResend$ | async"
          (resend)="onResendCode()"></wen-registration-code-resend>
      </form>
    </div>
  </wen-dialog-layout>

  <ng-template #verifyProgress>
    <wen-dialog-layout [headerGravity]="headerGravity">
      <div class="wen-registration-code-verification-progress-content" registrationContent>
        <mat-spinner [diameter]="78"></mat-spinner>
        <div class="wen-registration-code-verification-progress-text">{{ 'REGISTRATION_CODE_VERIFICATION_PROGRESS_TEXT'
          |
          translate: { phoneNumber: phoneNumber } }}</div>
      </div>
    </wen-dialog-layout>
  </ng-template>
</wen-adaptive-background>