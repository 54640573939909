<div class="wen-adaptive-background-desktop" *ngIf="isDesktopStyleDevice; else mobileContent">
  <div *ngIf="projectedContent" class="wen-adaptive-background-content-wrapper" [style.width]="desktopWidthPx">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>

<ng-template #mobileContent>
  <div *ngIf="projectedContent" class="wen-adaptive-background-content-wrapper">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <ng-content select="wen-adaptive-background-content, [wen-adaptive-background-content]"></ng-content>
</ng-template>