import { Component, Input, OnInit } from '@angular/core';
import { ActionMenuItem } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { ActionMenuItemProvider } from '../../../core/services/util/action-menu-item.provider';

@Component({
  selector: 'wen-message-type-selector',
  templateUrl: './message-type-selector.component.html',
  styleUrls: ['./message-type-selector.component.scss'],
  providers: [ActionMenuItemProvider]
})
export class MessageTypeSelectorComponent implements OnInit {

  actions$: Observable<ActionMenuItem[]>;

  @Input() menuId: string;
  @Input() disabled: boolean;

  constructor(private actionMenuItemProvider: ActionMenuItemProvider) { }

  ngOnInit(): void {
    this.actions$ = this.actionMenuItemProvider.selectActions(this.menuId);
  }
}
