import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { Component, TemplateRef, ViewChild, ViewEncapsulation, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { contextMenuAnimations } from './context-menu-animations';
import { WenTestIdModule } from '../../directives/public-api';
import { ContextMenuStyleDefinition } from './context-menu.models';

@Component({
  selector: 'wen-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    WenTestIdModule
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [contextMenuAnimations.slideInOut]
})

export class ContextMenuComponent implements OnDestroy {

  private onDestroy$ = new Subject<void>();
  private canBeClosed: boolean;
  style: { [key: string]: string };

  @Input() set styleDefinition(value: ContextMenuStyleDefinition) {
    this.style = { [value.styleName]: value.styleValue };
  }
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  /** Event to emit when menu should be closed */
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  canClose() {
    return this.canBeClosed;
  }

  onAnimationStart() {
    this.canBeClosed = false;
  }

  onAnimationEnd() {
    this.canBeClosed = true;
  }

  onTap() {
    this.closed.emit();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.closed.complete();
  }

}
