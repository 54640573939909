import { NgModule } from '@angular/core';
import { LottieModule } from 'ngx-lottie';
import { playerFactory } from '../message-box/components/embeds/embed-media/embed-media.module';
import { ChristmasComponent } from './christmas.component';

@NgModule({
  imports: [
    LottieModule.forRoot({
      player: playerFactory
    }),
  ],
  exports: [ChristmasComponent],
  declarations: [ChristmasComponent],
  providers: [],
})
export class ChristmasModule { }
