<form wenFormStore class="wen-invite-to-channel-selector-form wen-form" [formGroup]="channelSelectorFormGroup"
  *ngIf="availableChannels$ | async; let availableChannels; else emptyTemplate">
  <div class="wen-invite-to-channel-selector-form-label" portal-subheading-1>{{
    "INVITE_CHANNEL_STEP_1_SELECT_CHANNEL_LABEL" | translate }}</div>
  <div class="wen-invite-to-channel-selector-form-group wen-form-group">
    <wen-selection-list [formControlName]="'selectedChannels'" [selectionItems]="availableChannels"
      [selectionModel]="channelsSelectionModel" [selectionOptionPosition]="'postfix'"
      [selectionOptionBorderType]="'borderless'" [withImage]="true" [selectionOptionBorderType]="'normal'">
    </wen-selection-list>
  </div>
  <div portal-caption class="wen-invite-to-channel-selector-info"
    [class.wen-invite-to-channel-selector-info-error]="hasError">{{ "INVITE_CHANNEL_STEP_1_SELECT_CHANNEL_INFO" |
    translate }}</div>
</form>
<ng-template #emptyTemplate>
  <ng-container *ngIf="isLoaded">
    <wen-empty-list src="/assets/wen-widget/image/empty-channel-list-background.svg"
      titleLabel="INVITE_CHANNEL_CHANNEL_LIST_EMPTY_TITLE" textLabel="INVITE_CHANNEL_CHANNEL_LIST_EMPTY_DESCRIPTION">
    </wen-empty-list>
  </ng-container>
</ng-template>