<ng-container *ngIf="data$ | async; let data">
  <wen-context-menu #handleReceivedContextMenu>
    <wen-action-menu [actions]="contextMenuActionsHandleReceived" [data]="data"></wen-action-menu>
  </wen-context-menu>
  <wen-context-menu #handleDeletedContextMenu>
    <wen-action-menu [actions]="contextMenuActionsHandleDelete" [data]="data"></wen-action-menu>
  </wen-context-menu>
  <wen-button *ngIf="contactButtonProps$ | async; let contactButtonProps" class="wen-button-rounded wen-button-animated"
    [wenVariant]="contactButtonProps.wenVariant" [iconName]="contactButtonProps.iconName" iconSize="huge"
    (click)="contactButtonProps.action && handleAction($event, contactButtonProps.action, data)"
    [context-menu-trigger]="isDeleteContextMenu(contactButtonProps) ? handleDeletedContextMenu : handleReceivedContextMenu"
    [contextMenuTriggerDisabled]="contactButtonProps.action">
    <ng-container *ngIf="!iconOnly">
      {{ contactButtonProps.translationKey | translate }}
    </ng-container>
  </wen-button>
</ng-container>