import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { first, map, switchMap } from 'rxjs';
import { WenCommentCreate } from '../../../../shared/components/comments/types';
import { RootState } from '../../root/public-api';
import { removeDraftComment, sendComment } from '../comments.actions';
import { selectDraftComment } from '../comments.selectors';

export const createSendCommentEffect = (
  actions$: Actions,
  store: Store<RootState>,
  socketIoService: Pick<SocketIoService, 'comment'>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(sendComment),
      switchMap(() => store.pipe(
        select(selectDraftComment),
        first(),
        map(draftMessage => {
          const payload: WenCommentCreate = {
            parentId: draftMessage.contextId,
            text: draftMessage.content,
            quotedCommentId: draftMessage.quote?.quotedCommentId
          };

          socketIoService.comment.create.emit(payload);
          return removeDraftComment();
        })
      ))
    )
  );
};
