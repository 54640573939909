<div class="wen-selection-list-item-content"  [wen-test-id]="'wen-selection-list-item'" cdkSelectionToggle>
  <mat-checkbox *ngIf="!isDisabled"
    [class.multi-check]="isMultiSelect"
    [class.single-check]="!isMultiSelect"
    [class.borderless-check]="selectionOptionBorderType === 'borderless'"
    wen-primary-color
    [disableRipple]="'true'"
    [ngModel]="selected"
  ></mat-checkbox>
  <span class="wen-selection-list-item-label">
    <wen-icon *ngIf="selectionOptionPrefixIcon" [iconName]="selectionOptionPrefixIcon" class="wen-icon-medium"></wen-icon>
    <ng-content></ng-content>
  </span>
</div>
