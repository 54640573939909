import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable, ViewContainerRef } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { BehaviorSubject, first, merge, shareReplay, skipWhile } from 'rxjs';
import { CONTEXT_MENU_ID, ContextMenuConfig, ContextMenuRef } from '../../../components/context-menu/context-menu-ref';
import { ContextMenuComponent } from '../../../components/context-menu/context-menu.component';
import { EventListenerManager } from '../../../util/event-listener-manager';
import { OverlayOpener } from '../overlay-types';
import { isNotOnScreenEdge } from './edge-detection-util';

const isValidScrollEvent = (event: TouchEvent, hammerRoot: HTMLElement) => {
  let currentElement = event.target as HTMLElement;
  while (currentElement && currentElement !== hammerRoot) {
    const isScrollable = currentElement.scrollHeight > currentElement.clientHeight;
    if (isScrollable && currentElement !== hammerRoot) {
      return false;
    }
    currentElement = currentElement.parentElement;
  }
  return true;
};

@Injectable()
export class ContextMenuOverlayService implements OverlayOpener {

  private eventListenerManager = new EventListenerManager();

  private openedContextMenus: ContextMenuRef[] = [];
  private attachedMenu: ContextMenuComponent;
  private hasOpenOverlay = new BehaviorSubject<boolean>(false);

  hasOpenOverlay$ = this.hasOpenOverlay.pipe(
    shareReplay(1)
  );

  constructor(
    private overlay: Overlay,
    private eventManager: EventManager
  ) { }

  openOverlay(contextMenu: ContextMenuComponent, viewContainerRef: ViewContainerRef) {
    if (this.getContextMenuById(CONTEXT_MENU_ID)) {
      throw Error(`Context menu with id ${CONTEXT_MENU_ID} exists already. The context menu id must be unique.`);
    }
    this.attachedMenu = contextMenu;
    const menuConfig: ContextMenuConfig = {
      hasBackdrop: true,
      panelClass: 'wen-context-menu-panel',
      id: CONTEXT_MENU_ID,
      positionStrategy: new GlobalPositionStrategy()
    };

    const overlayRef = this.overlay.create(menuConfig);
    const contextMenuRef = new ContextMenuRef(overlayRef, menuConfig);
    this.openContextMenus.push(contextMenuRef);

    overlayRef.attach(new TemplatePortal(contextMenu.templateRef, viewContainerRef));

    this.listenToMenuClose();
    this.listenToSwipeDown();
    this.preventOverlayEdgeSwipe();
    this.hasOpenOverlay.next(true);
  }

  isOpen() {
    return Boolean(this.attachedMenu);
  }

  close() {
    if (!this.openContextMenus.length) {
      return;
    }
    this.eventListenerManager.detachAll();
    this.openContextMenus[0].menuRef.detach();
    this.removeOpenContextMenu();
    this.attachedMenu = null;
    this.hasOpenOverlay.next(false);
  }

  private listenToMenuClose() {
    if (!this.openContextMenus.length && !this.attachedMenu) {
      return;
    }
    const overlayRef = this.openContextMenus[0].menuRef;
    const backdrop$ = overlayRef.backdropClick().pipe(
      skipWhile(() => !this.attachedMenu.canClose())
    );
    const detachments$ = overlayRef.detachments();
    merge(
      backdrop$,
      detachments$,
      this.attachedMenu.closed
    ).pipe(
      first()
    ).subscribe(() => {
      this.close();
    });
  }

  private listenToSwipeDown() {
    let startY = 0;
    let endY = 0;
    const overlayRef = this.openContextMenus[0].menuRef;
    const rootElement = overlayRef.overlayElement;

    this.eventListenerManager.addEvent = this.eventManager.addEventListener(rootElement, 'touchstart', (event: TouchEvent) => {
      if (!isValidScrollEvent(event, rootElement)) {
        return;
      }
      startY = event.targetTouches[0].clientY;
      endY = startY;
    });

    this.eventListenerManager.addEvent = this.eventManager.addEventListener(overlayRef.overlayElement, 'touchmove', (event: TouchEvent) => {
      if (!isValidScrollEvent(event, rootElement)) {
        return;
      }
      const ydiff = Math.round(event.targetTouches[0].clientY - endY);

      if (ydiff > 0) {
        overlayRef.overlayElement.setAttribute('style', `transform: translateY(${ydiff}px)`);
      }
    });

    this.eventListenerManager.addEvent = this.eventManager.addEventListener(overlayRef.overlayElement, 'touchend', (event: TouchEvent) => {
      if (!isValidScrollEvent(event, rootElement)) {
        return;
      }
      const overlayHeight = overlayRef.overlayElement.offsetHeight;
      const movedHeight = Math.round(event.changedTouches[0].clientY - startY);

      if (Math.floor((movedHeight * 100) / overlayHeight) > 20) {
        this.close();
      } else {
        overlayRef.overlayElement.setAttribute('style', `transform: translateY(0)`);
      }
    });
  }

  private preventOverlayEdgeSwipe() {
    const ignoreEdgeSwipe = (event) => {
      if (isNotOnScreenEdge(event)) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
    };

    this.eventListenerManager.addEvent = this.eventManager.addEventListener(
      this.openContextMenus[0].menuRef.backdropElement,
      'touchstart',
      (event) => {
        ignoreEdgeSwipe(event);
      }
    );

    this.eventListenerManager.addEvent = this.eventManager.addEventListener(
      this.openContextMenus[0].menuRef.overlayElement,
      'touchstart',
      (event) => {
        ignoreEdgeSwipe(event);
      }
    );
  }

  private getContextMenuById(id: string) {
    return this.openContextMenus.find(contextMenu => contextMenu.id === id);
  }

  private get openContextMenus(): ContextMenuRef[] {
    return this.openedContextMenus;
  }

  private removeOpenContextMenu() {
    if (this.openContextMenus.length) {
      this.openContextMenus.splice(0, 1);
    }
  }

}
