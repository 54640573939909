import { Component, DestroyRef, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectUnitedKioskFlatCredential } from '../../../../../core/store/wallet/wallet.selectors';
import { WalletCredentialType } from '@portal/wen-backend-api';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from '../../../../../shared/shared.module';

@Component({
  selector: 'wen-credentials-menu',
  templateUrl: './credentials-menu.component.html',
  styleUrls: ['./credentials-menu.component.scss'],
  imports: [
    SharedModule
  ],
  standalone: true
})
export class CredentialsMenuComponent {
  destroyRef = inject(DestroyRef);

  public unitedKioskCredential$ = this.store.pipe(select(selectUnitedKioskFlatCredential), takeUntilDestroyed(this.destroyRef));

  constructor(private store: Store<RootState>, private readonly navigationHelper: WenNavigationHelper) { }

  public navigateToCredentialDetails(type: WalletCredentialType) {
    this.navigationHelper.navigateToCredentialDetails(type);
  }
}
