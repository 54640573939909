<div class="wen-app-header-wrapper">
  <div class="wen-app-header-left-part" *ngIf="hasCommand" [wen-test-id]="'wen-app-header-left-command'">
    <wen-header-command *ngIf="leftCommand" [commandData]="leftCommand" [isCommandDisabled]="leftCommandDisabled"></wen-header-command>
  </div>
  <div class="wen-app-header-center-part">
    <div *ngIf="showLogo"
         class="wen-app-header-app-logo"
         [ngStyle]="{'backgroundImage': logoIcon ? 'url(' + logoIcon + ')' : 'var(--wen-logo-url)'}"
         [wen-test-id]="'wen-app-header-app-logo'"
         nativeBridgeDebugTarget
         (click)="centerClicked()">
    </div>
    <div *ngIf="title" class="wen-app-header-title"
      [class.wen-app-header-title-text-spacing]="titleIcon || prefixTitleIcon || !hasCommand" (click)="centerClicked()">
      <span portal-title class="wen-app-header-title-text" [wen-test-id]="'wen-app-header-title-text'">
        {{ title | translate }}
        <wen-icon class="wen-app-header-title-prefix-icon wen-icon-small" *ngIf="prefixTitleIcon" [iconName]="prefixTitleIcon"></wen-icon>
        <wen-icon class="wen-app-header-title-icon wen-icon-small" *ngIf="titleIcon" [iconName]="titleIcon"></wen-icon>
      </span>
    </div>
    <div *ngIf="subTitle" class="wen-app-header-subtitle" portal-caption>
      {{ subTitle | translate }}
    </div>
  </div>
  <div class="wen-app-header-right-part" *ngIf="hasCommand" [wen-test-id]="'wen-app-header-right-command'">
    <ng-container *ngIf="rightCommand">
      <wen-header-command *wenComingSoonFeature="rightCommand.comingSoonFeature" [commandData]="rightCommand" [isCommandDisabled]="rightCommandDisabled"></wen-header-command>
    </ng-container>
  </div>
</div>
