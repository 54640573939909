import { Injectable } from '@angular/core';
import { OverlayManager } from '@portal/wen-components';
import { UnencryptedChatDialogComponent } from './unencrypted-chat-dialog/unencrypted-chat-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { WenStorageService } from '../../../../core/services/storage/wen-storage.service';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';
import { filter, first } from 'rxjs';

@Injectable()
export class UnencryptedChatDialogOpenerService {
  constructor(
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
    private storageService: WenStorageService,
    private featureEnablementService: FeatureEnablementService
  ) { }

  openUnencryptedChatDialog() {
    if (
      this.featureEnablementService.featureFlagMethods.isEnableEncryptedChatMessageSending() ||
      this.storageService.isUnencryptedChatDialogDismissed()
    ) {
      return;
    }

    this.overlayManager.hasOpenOverlay$.pipe(
      first(),
      filter(hasOpenOverlay => !hasOpenOverlay)
    ).subscribe(() => {
      this.openOverlay();
    });
  }

  private openOverlay() {
    this.overlayManager.dialog.openConfirmationDialog(UnencryptedChatDialogComponent, {
      header: this.translateService.instant('UNENCRYPTED_CHAT_DIALOG_HEADER'),
      headerImage: '/assets/wen-widget/image/chat-work-in-progress-lock.svg',
    }, { disableClose: true, closeOnNavigation: false });
  }
}
