import { ComponentType } from '@angular/cdk/portal';
import { Component, Injectable, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { ConfirmationDialogComponent, SelectionOption, OverlayManager } from '@portal/wen-components';
import { filter, first, map, Observable, of, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';
import { firstExisty } from '../../../../../../../core/common/operators/first-existy';
import { isNullOrUndefined } from '../../../../../../../core/common/operators/null-check-util';
import { TrimFieldValueAdapter } from '../../../../../../../core/common/util/field-adapters/trim-field-value-adapter';
import { appNativeUriValidator, appWebURLValidator } from '../../../../../../../core/common/util/field-validators';
import { toSelectionOptions } from '../../../../../../../core/common/util/to-selection-option';
import { WenNavigationHelper } from '../../../../../../../core/services/navigation/types';
import { selectAppPermission } from '../../../../../../../core/store/apps/apps.selectors';
import { AppEntity } from '../../../../../../../core/store/apps/apps.state';
import { RootState } from '../../../../../../../core/store/root/public-api';
import { FormStoreService } from '../../../../../../../shared/form-store/form-store.service';
import { AppFormDataProvider, APP_EDIT_FORM_DATA_PROVIDER } from '../../providers/app-form-data-provider';
import { AppNavigator } from '../../../../../../../core/services/navigation/app-navigator';

@Injectable()
class MatDialogOverride extends MatDialog {
  open<T, D = any, R = any>(component: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    return super.open(component, config ? { ...config, data: { ...config.data, aspectRatio: 1 } } : { data: { aspectRatio: 1 } });
  }
}

@Component({
  selector: 'wen-app-info-edit',
  templateUrl: './app-info-edit.component.html',
  styleUrls: ['./app-info-edit.component.scss'],
  providers: [
    APP_EDIT_FORM_DATA_PROVIDER,
    {
      provide: MatDialog,
      useClass: MatDialogOverride
    }
  ]
})
export class AppInfoEditComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  fieldValueAdapter = new TrimFieldValueAdapter();

  appEditFormGroup = new FormGroup({
    icon: new FormControl(''),
    title: new FormControl('', {
      validators: [
        Validators.required
      ],
      updateOn: 'blur'
    }),
    description: new FormControl(''),
    uri: new FormControl('', {
      validators: [
        Validators.required,
        appWebURLValidator()
      ],
      updateOn: 'blur'
    }),
    nativeUri: new FormControl('', {
      validators: [
        appNativeUriValidator()
      ],
      updateOn: 'blur'
    }),
    androidPlayStoreId: new FormControl(''),
    iosAppName: new FormControl(''),
    iosAppStoreId: new FormControl(''),
    categories: new FormControl([]),
  });

  allCategories$: Observable<SelectionOption<string>[]>;
  canDelete$: Observable<boolean>;
  canOpenCategorySelector$: Observable<boolean>;

  app$: Observable<AppEntity>;

  get imageFallbackText() {
    return this.appEditFormGroup.controls.title.value;
  }

  get selectMainLabel() {
    return this.translateService.instant('CATEGORY_SELECTOR_LABEL');
  }

  get selectBottomLabel() {
    return this.translateService.instant('APP_EDIT_FORM_APP_CATEGORY_SELECT_LABEL');
  }

  constructor(
    private readonly store: Store<RootState>,
    private readonly formStoreService: FormStoreService,
    private readonly formDataProvider: AppFormDataProvider,
    private readonly overlayManager: OverlayManager,
    private readonly translateService: TranslateService,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly appNavigator: AppNavigator,
    private readonly socketIoService: SocketIoService,
  ) { }

  ngOnInit() {
    this.app$ = this.formDataProvider.app$;

    this.app$.pipe(
      firstExisty(),
      map(({ id, title, description, icon, uri, nativeUri, androidPlayStoreId, iosAppName, iosAppStoreId, categories }) => {
        return {
          id, title, description, icon, uri, nativeUri,
          androidPlayStoreId, iosAppName, iosAppStoreId,
          categories: categories?.length ? toSelectionOptions(categories) : []
        };
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(data => {
      this.formStoreService.initializeForm(data);
    });

    this.canDelete$ = this.app$.pipe(
      switchMap((app) => {
        if (isNullOrUndefined(app)) {
          return of(null);
        }
        return this.store.pipe(
          select(selectAppPermission),
          map((appPermissionFn) => appPermissionFn(app.id))
        );
      }),
      map(permission => permission?.canDelete),
      takeUntil(this.onDestroy$)
    );

    this.allCategories$ = this.socketIoService.app.getCategories.listen.pipe(
      first(),
      map((category) => toSelectionOptions(category)),
      shareReplay(1)
    );

    this.canOpenCategorySelector$ = this.allCategories$.pipe(
      map((selections) => selections.length > 0),
    );
    this.socketIoService.app.getCategories.emit();
  }

  deleteApp(appId: string) {
    if (!appId) {
      return;
    }
    const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant('DELETE_CONFIRM_DIALOG_TITLE'),
      content: this.translateService.instant('APP_DELETE_CONFIRM_DIALOG_TEXT'),
      okLabel: this.translateService.instant('DELETE_BUTTON_LABEL'),
      dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL'),
    });

    dialog.afterClosed().pipe(
      first(),
      filter(data => data?.result === 'ok'),
      switchMap(() => this.socketIoService.app.delete.acknowledgement$({ id: appId }))
    ).subscribe(() => {
      this.socketIoService.app.details.emit({ id: appId });
      this.formStoreService.clearFormData();
      this.navigationHelper.doUserAppListBackNavigation();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
