
<ng-container *ngIf="restrictionModel">
  <ng-container *ngIf="restrictionModel?.layoutType === 'buttonLayout'; then buttonLayout else infoLayout"></ng-container>
</ng-container>

<ng-template #infoLayout>
  <div class="wen-channel-restriction-wrapper wen-info-layout" *ngIf="restrictionModel">
    <div portal-body-2 class="wen-channel-restriction-title">{{ restrictionModel.titleTranslationKey | translate }}</div>
    <div portal-caption class="wen-channel-restriction-description">{{ restrictionModel.descriptionTranslationKey | translate }}</div>
    <img [src]="restrictionModel.imagePath"/>
  </div>
</ng-template>

<ng-template #buttonLayout>
  <div class="wen-channel-restriction-wrapper wen-button-layout">
    <img [src]="restrictionModel.imagePath"/>
    <div class="wen-restriction-content">
      <div class="wen-restriction-headline">
        <wen-channel-indicator class="wen-premium-indicator wen-channel-indicator-large"
                               *ngIf="restrictionModel.premiumReadingChannel"
                               [indicatorType]="IndicatorType.PREMIUM_READING_CHANNEL"
        ></wen-channel-indicator>
        <div portal-headline>{{ restrictionModel.titleTranslationKey | translate }}</div>
      </div>
      <div portal-body-2 class="wen-channel-restriction-description">{{ restrictionModel.descriptionTranslationKey | translate }}</div>
      <wen-rounded-button (click)="restrictionModel?.action.handler()">
        {{ restrictionModel?.action.translationKey | translate }}
      </wen-rounded-button>
    </div>
  </div>
</ng-template>
