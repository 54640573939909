<wen-dialog-layout [headerGravity]="'top'">
  <div wen-dialog-layout-static-header class="wen-filter-selector-header" portal-title>{{
    'GEO_FILTER_DIALOG_TITLE' |
    translate }}</div>
    <form dialogLayoutContent class="wen-selector-form wen-form" [formGroup]="formGroup" >
      <div class="wen-form-group" [wen-test-id]="'geo-filter-form'">
        <div class="wen-form-field-wrapper">
          <mat-form-field class="wen-location-selector-field" (click)="openLocationSelector()">
            <mat-label>{{ 'GEO_FILTER_DIALOG_ADDRESS_FIELD_LABEL' | translate }}</mat-label>
            <portal-field [formControlName]="'address'" [readonly]="true" autocomplete="'off'" [wen-test-id]="'location-search-field'"></portal-field>
            <wen-icon class="wen-filter-selector-address-field-icon wen-icon-medium" *ngIf="!hasAddress" matSuffix [iconName]="'add_location'"></wen-icon>
            <wen-icon class="wen-filter-selector-address-field-icon wen-icon-medium" *ngIf="hasAddress" matSuffix [iconName]="'close'" (click)="clearAddress($event)"></wen-icon>
          </mat-form-field>
        </div>
        <div class="wen-filter-current-location-picker" wen-primary-color (click)="useCurrentLocation()">
          <wen-icon class="wen-icon-medium" wen-primary-color iconName="current_location"></wen-icon>
          <span>{{ 'GEO_FILTER_DIALOG_CURRENT_LOCATION_BUTTON_LABEL' | translate }}</span>
        </div>
        <div class="wen-form-field-wrapper wen-geo-radius-wrapper" [@collapse]="!!formGroup.value.address">
          <wen-slider *ngIf="sliderValues?.length" [formControlName]="'radius'" [value]="formGroup.value.radius" [rangeValues]="sliderValues">
            <div class="wen-form-field-label" wen-slider-label portal-caption>{{ 'GEO_FILTER_DIALOG_RADIUS_FIELD_LABEL' | translate }}</div>
          </wen-slider>
          <mat-form-field>
            <input matInput formControlName="radius" type="number" min="0" autocomplete="off" [value]="formGroup.value.radius" pattern="\d*" [valueAdapter]="fieldValueAdapter" />
          </mat-form-field>
        </div>
      </div>
    </form>
  <div wen-dialog-layout-static-footer [wen-test-id]="'geo-filter-footer'">
    <mat-dialog-actions class="wen-filter-dialog-actions">
      <button class="wen-filter-dialog-button" wen-primary-color (click)="onApply()" [wen-test-id]="'geo-filter-apply-button'">{{ "APPLY_BUTTON" |
        translate }}</button>
      <button class="wen-filter-dialog-button" wen-primary-color (click)="onCancel()" [wen-test-id]="'geo-filter-cancel-button'">{{ "CANCEL_BUTTON_LABEL" |
        translate }}</button>
    </mat-dialog-actions>
  </div>
</wen-dialog-layout>
