
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { selectCurrentUserData } from '../../../../../core/store/auth/auth.selectors';
import { editChannelMessage } from '../../../../../core/store/channel/channel.actions';
import { selectCurrentChannelPermission } from '../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { MessageBoxModel } from '../../../feed/components/we-feed/we-feed-model';
import { isDocumentEmbed, isMediaEmbed, isPollEmbed } from '../../../../../core/store/channel/util/embed-type-helper';
import { EmbeddedMedia } from '../../../../../core/store/channel/channel.state';
import { isMediaPlayable } from '../../../../util/embed-media-util';

@Injectable()
export class MessageBoxEditAction implements ActionMenuItem {

  label: string;
  icon = 'edit';

  private currentUserId$ = this.store.pipe(
    select(selectCurrentUserData),
    firstExisty(),
    map(({ userId }) => userId),
  );

  private parentInfo$ = this.store.pipe(
    select(selectCurrentChannelPermission),
    withLatestFrom(this.currentUserId$)
  );

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>
  ) {
    this.label = this.translateService.instant('MESSAGE_BOX_CONTEXT_MENU_LABEL_EDIT');
  }

  isVisible$(data: MessageBoxModel) {
    const isPoll = data.embeds.some(embed => isPollEmbed(embed));
    const isDocument = data.embeds.some(embed => isDocumentEmbed(embed));
    const editableMediaEmbeds = data.embeds
      .filter(embed => isMediaEmbed(embed))
      .every((embed: EmbeddedMedia) => isMediaPlayable(embed) || embed.error) ?? true;
    return this.parentInfo$.pipe(
      map(([permissions, currentUserId]) => {
        return ((permissions?.canEditAnyMessage || (currentUserId === data.authorId && permissions?.canEditOwnMessage))
          && editableMediaEmbeds && !isPoll && !isDocument);
      }),
      distinctUntilChanged()
    );
  }

  execute(data: MessageBoxModel) {
    this.store.dispatch(editChannelMessage({ messageId: data.messageId }));
  }

}
