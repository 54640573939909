import { createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { EventCategoryDTO, EventListResponse, EventPaging, EventSubscriptionResponse, ListEventRequestParameters } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { LoadingState } from '../../common/types/store-loading-state';
import { EventEntity, OccurenceEntity, SubscriptionEntity } from './event.state';

export const initializeEventFilters = createAction(
  '[Events] Initialize event filters'
);

export const reloadEventDateFilters = createAction(
  '[Events] Reload event date filters'
);

export const fetchDiscoveryEvents = createAction(
  '[Events] Fetch discovery events',
  props<Partial<{ filter: string }>>()
);

export const fetchDiscoveryEventsNextPage = createAction(
  '[Events] Fetch next page of discovery events'
);

export const fetchUserEvents = createAction(
  '[Events] Fetch user events',
  props<Partial<{ filter: string }>>()
);

export const fetchUserEventNextPage = createAction(
  '[Events] Fetch next page of user events'
);

export const fetchUserEventsWithFilters = createAction(
  '[Events] Fetch user events with filters',
  props<Partial<{ filterString: string }>>()
);

export const fetchDiscoveryEventsWithFilters = createAction(
  '[Events] Fetch discovery events with filters',
  props<Partial<{ filterString: string }>>()
);

export const fetchEventDetail = createAction(
  '[Events] Fetch detail of an event',
  props<{ eventId: string }>()
);

export const subscribeToUserEventUpdates = createAction(
  '[Events] Subscribe to event updates for the logged in user'
);

export const updateEventDetails = createAction(
  '[Events] Update event details',
  props<{ events: Partial<EventEntity>[] }>()
);

export const subscribeForEvent = createAction(
  '[Events] Subscribe to event',
  (eventId: string = null) => ({ eventId })
);

export const unsubscribeFromEvent = createAction(
  '[Events] Unsubscribe from event',
  (eventId: string = null) => ({ eventId })
);
export const updateEventSubscription = createAction(
  '[Events] Update subscription of an event',
  props<EventSubscriptionResponse>()
);

export const updateuserEventsPaging = createAction(
  '[Events] Update user events paging',
  props<{ userEventsPaging: EventPaging }>()
);

export const updateDiscoveryEventsPaging = createAction(
  '[Events] Update discovery events paging',
  props<{ discoverEventsPaging: EventPaging }>()
);

export const setEventSubscriptions = createAction(
  '[Events] Set subscriptions of events',
  props<{ subscriptions: SubscriptionEntity[] }>()
);

export const extractSubscription = createAction(
  '[Events] Extract subscription info from occurences',
  props<{ data: OccurenceEntity[] }>()
);

export const updateUserEventsLoadingState = createAction(
  '[Events] Update the loading state of the user specific events',
  props<{ loadingState: LoadingState }>()
);

export const updateDiscoveryEventsLoadingState = createAction(
  '[Events] Update the loading state of the discovery events',
  props<{ loadingState: LoadingState }>()
);

export const fetchNextPageEvents = createAction(
  '[Events] Fetch next chunk of events',
  props<{
    emitter: (request?: ListEventRequestParameters) => Observable<EventListResponse>;
    pagingParams: EventPaging;
    onSuccess?: (data: EventListResponse) => TypedAction<any>;
  }>()
);

export const updateVisibleDiscoveryEvents = createAction(
  '[Events] Update visible discovery events',
  props<{ ids: string[] }>()
);

export const updateUserEventVisibility = createAction(
  '[Events] Update user event visibility',
  props<{ eventId: string; subscribed: boolean}>()
);

export const onUserEventListResult = createAction(
  '[Events] On user result list',
  props<EventListResponse>()
);

export const onDiscoveryEventListResult = createAction(
  '[Events] On discovery result list',
  props<EventListResponse>()
);

export const updateDiscoveryListOccurences = createAction(
  '[Events] Update discovery list occurences',
  props<{ data: OccurenceEntity[] }>()
);

export const appendDiscoveryListOccurences = createAction(
  '[Events] Append discovery list occurences',
  props<{ data: OccurenceEntity[] }>()
);

export const updateUserListOccurences = createAction(
  '[Events] Update user list occurences',
  props<{ data: OccurenceEntity[] }>()
);

export const appendUserListOccurences = createAction(
  '[Events] Append user list occurences',
  props<{ data: OccurenceEntity[] }>()
);

export const upsertCategories = createAction(
  '[Events] Update categories',
  props<{ categories: EventCategoryDTO[]}>()
);
