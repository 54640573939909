import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { selectFeaturedData } from '../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { MessagePreviewCardContext } from '../../../../shared/components/message-preview-card/message-preview-card.component';
import { fetchCurrentWeatherData } from '../../../../core/store/weather/weather.actions';
import { FeaturedSectionConfig, WeatherCustomizationConfig } from '@portal/wen-backend-api';
import { NetworkClientConfigService } from '../../../../core/services/util/network-client-config-service';
import { selectCurrentWeatherModel } from '../../../../core/store/weather/weather.selectors';
import { WeatherModel } from '@portal/wen-components';

@Component({
  selector: 'wen-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  public featuredData$: Observable<MessagePreviewCardContext>;
  public weatherData$: Observable<WeatherModel>;
  public weatherConfig: WeatherCustomizationConfig;
  public homeCoverImage$: Observable<string>;
  public featuredSectionConfigs?: FeaturedSectionConfig[];
  public buttonSectionLink?: string;
  public canNavigateOnWeatherWidget: boolean;

  @HostBinding('class.wen-home') className = true;

  constructor(
    private readonly store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly networkClientConfigService: NetworkClientConfigService,
  ) { }

  ngOnInit() {

    this.featuredData$ = this.store.pipe(
      select(selectFeaturedData),
      map((data) => {
        if (!data?.channel || !data.message) {
          return null;
        }
        return data;
      })
    );

    this.weatherConfig = this.networkClientConfigService.weather;
    this.canNavigateOnWeatherWidget = Boolean(this.weatherConfig?.goToLink);
    if (this.weatherConfig?.location && this.weatherConfig?.countryCode2Letters) {
      this.store.dispatch(fetchCurrentWeatherData({
        location: this.weatherConfig.location,
        countryCode: this.weatherConfig.countryCode2Letters
      }));
      this.weatherData$ = this.store.pipe(select(selectCurrentWeatherModel));
    }

    this.homeCoverImage$ = this.networkClientConfigService.homeCoverImage$;
    this.featuredSectionConfigs = this.networkClientConfigService.featuredSections;
    this.buttonSectionLink = this.networkClientConfigService.buttonSectionLink;
  }

  featuredCardClicked() {
    this.navigationHelper.navigateToChannelFeaturedView();
  }

  weatherClicked() {
    this.openGoToLink(this.weatherConfig?.goToLink);
  }

  buttonSectionClicked() {
    this.openGoToLink(this.buttonSectionLink);
  }

  private openGoToLink(link: string | (() => void) | undefined) {
    if (!link) {
      return;
    }
    if (typeof link === 'string') {
      if (link.startsWith('mailto:')) {
        const mail = link.replace('mailto:', '');
        this.navigationHelper.navigateToSendEmailTo(mail);

      } else {
        this.navigationHelper.navigateToUrlWithFallback(link);
      }
    } else {
      link();
    }
  }
}
