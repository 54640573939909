<ng-container [formGroup]="parentGroup">
  <div portal-subheading-1 class="wen-form-group-hint wen-multiselect-label">
    {{ contextValue.label }}
  </div>
  <ng-container [formGroupName]="contextValue.name">
      <div class="wen-form-field" *ngFor="let item of possibleValues | keyvalue">
        <label>{{ labelMap.get(item.key) }} </label>
        <mat-slide-toggle
          color="primary"
          [formControlName]="item.key"
        ></mat-slide-toggle>
      </div>
  </ng-container>
</ng-container>
