<div class="wen-poll-option-wrapper">
  <div class="wen-poll-option-textarea-wrapper">
    <textarea matInput
      cdkTextareaAutosize
      #textArea
      [placeholder]="placeholder"
      [formControl]="control"
      (keydown.enter)="onenter($event)"
      [maxlength]="CHARACTER_LIMIT"
    ></textarea>
  </div>
  <div class="character-count-and-remove-wrapper">
    <wen-icon wen-primary-color *ngIf="removable" iconName="close" (click)="removeOption()"></wen-icon>
    <mat-hint
      *ngIf="characterCount$ | async as characterCount"
    >{{ characterCount }}/{{ CHARACTER_LIMIT }}</mat-hint>
  </div>
</div>
