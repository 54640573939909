<div class="wen-channel-detail-layout" cdkScrollable>
  <ng-container *ngIf="currentChannel$ | async; let currentChannel">
    <ng-container *ngIf="headerData$ | async; let headerData">
      <wen-detail-header [model]="headerData">
        <button *ngIf="canEdit$ | async" wen-detail-header-quick-actions mat-mini-fab color="primary"
          class="mat-elevation-z0" (click)="editChannel(currentChannel.id)" [wen-test-id]="'channel-detail-edit-button'">
          <wen-icon iconName="edit"></wen-icon>
        </button>
        <wen-mute-unmute-channel-button wen-detail-header-quick-actions [channel]="currentChannel" [wen-test-id]="'channel-detail-mute-unmute-button'">
        </wen-mute-unmute-channel-button>
        <div wen-detail-header-title [class.wen-channel-detail-header-has-title-icon]="headerData.prefixTitleIcon">
          <wen-icon class="wen-app-header-title-prefix-icon wen-icon-small" *ngIf="headerData.prefixTitleIcon"
            [iconName]="headerData.prefixTitleIcon"></wen-icon>
          <span portal-title class="wen-channel-detail-header-title-text">{{ headerData.title }}</span>
        </div>
        <wen-forward-button *ngIf="canForward$ | async" wen-detail-header-quick-actions [deepLink]="channelDeepLink" [context]="forwardContext"></wen-forward-button>
        <button wen-detail-header-quick-actions *ifSubscribedTo="currentChannelId$ | async; extraCondition: isLeaveButtonVisible$ | async" mat-mini-fab color="primary"
          class="mat-elevation-z0" (click)="unSubscribeFromChannel()" [wen-test-id]="'channel-detail-unsubscribe-button'">
          <wen-icon iconName="logout"></wen-icon>
        </button>
      </wen-detail-header>
    </ng-container>
    <div class="wen-channel-detail-content-group">
      <div class="wen-channel-detail-field" *ngIf="currentChannel.description">
        <div class="wen-channel-detail-field-label" portal-caption>{{ 'LABEL_INFO' | translate }}</div>
        <div class="wen-channel-detail-field-text" portal-body>{{ currentChannel.description }}</div>
      </div>
      <div class="wen-channel-detail-field" *ngIf="currentChannel.imprint">
        <div class="wen-channel-detail-field-label" portal-caption>{{ 'LABEL_IMPRINT' | translate }}</div>
        <div class="wen-channel-detail-field-text" portal-body [innerText]="currentChannel.imprint"></div>
      </div>
      <div class="wen-channel-detail-field" *ngIf="currentChannel.dataProtectionUrl">
        <div class="wen-channel-detail-field-label" portal-caption>{{ 'LABEL_DATA_PROTECTION' | translate }}</div>
        <div class="wen-channel-detail-field-text">
          <a (click)="openUrl(currentChannel.dataProtectionUrl)">{{ currentChannel.dataProtectionUrl }}</a>
        </div>
      </div>
      <wen-read-categories-field
        class="wen-channel-detail-field"
        *ngIf="currentChannel.categories?.length"
        [fieldLabel]="'CHANNEL_DETAIL_CATEGORY_DESCRIPTION' | translate"
        [categories]="currentChannel.categories">
      </wen-read-categories-field>
      <div class="wen-channel-detail-field" *ngIf="channelDeepLink"
        (click)="shareDeepLink()">
        <div class="wen-channel-detail-field-label" portal-caption>{{ 'LABEL_COPY_CHANNEL_LINK' | translate }}</div>
        <div class="wen-channel-detail-field-text wen-channel-detail-deep-link-container">
          <div portal-body>{{ channelDeepLink }}</div>
          <wen-icon [iconName]="'share'"></wen-icon>
        </div>
      </div>
    </div>
    <div class="wen-channel-detail-content-group wen-channel-detail-submenus" *ngIf="isAdminGroupVisible$ | async">
      <wen-menu-entry (click)="navigateToAdminListPage(currentChannel.id)"
      *ngIf="canListAdmins$ | async">
        <wen-icon [iconName]="'user_shield_on'" [fillColor]="adminListMenuEntryFillColor"
          class="wen-icon-medium" color="white" menuEntryIcon>
        </wen-icon>
        <span menuEntryTitle>{{ 'CHANNEL_ADMIN_LIST_FORM_TITLE' | translate }}</span>
        <span menuEntryDescription>{{ adminCount$ | async }}</span>
      </wen-menu-entry>
      <wen-menu-entry (click)="navigateToChannelJoinRequestPage(currentChannel.id)"
        *ngIf="canHandleJoinRequests$ | async">
        <wen-icon [iconName]="'user_raise_hand'" [fillColor]="visibilitySettingsMenuEntryFillColor" color="white"
          class="wen-icon-medium" menuEntryIcon>
        </wen-icon>
        <span menuEntryTitle>{{ 'CHANNEL_PENDING_JOIN_REQUESTS_FORM_TITLE' | translate }}</span>
        <span menuEntryDescription>{{ pendingJoinRequestCount$ | async }}</span>
      </wen-menu-entry>
      <wen-menu-entry *ngIf="isContentProviderListVisible$ | async" (click)="navigateToContentProviderListPage(currentChannel.id)">
        <wen-icon iconName="author" [fillColor]="contentProviderMenuEntryFillColor" color="white"
          class="wen-icon-medium" menuEntryIcon></wen-icon>
        <span menuEntryTitle>{{ 'CHANNEL_CONTENT_PROVIDER_TITLE' | translate }}</span>
        <span menuEntryDescription>{{ contentProviderCount$ | async }}</span>
      </wen-menu-entry>
      <wen-menu-entry *ngIf="isSubscriberListVisible$ | async" (click)="navigateToSubscriberListPage(currentChannel.id)">
        <wen-icon iconName="group" [fillColor]="channelSubscribersMenuEntryFillColor" color="white"
          class="wen-icon-medium" menuEntryIcon></wen-icon>
        <span menuEntryTitle>{{ 'CHANNEL_SUBSCRIBERS_TITLE' | translate }}</span>
        <span menuEntryDescription>{{ subscriberCount$ | async }}</span>
      </wen-menu-entry>
      <div class="wen-list-info-text" *ngIf="infoTextForCollaborativeChannels$ | async as infoText" portal-caption>{{ infoText | translate }}</div>
    </div>
    <ng-container *ifSubscribedTo="currentChannelId$ | async">
      <div class="wen-channel-detail-content-group wen-channel-detail-submenus" *ngIf="isSettingsVisible$ | async">
        <wen-menu-entry (click)="navigateToChannelSettings(currentChannel.id)">
          <wen-icon iconName="settings" [fillColor]="channelSettingsMenuEntryFillColor" color="white"
            class="wen-icon-medium" menuEntryIcon></wen-icon>
          <span menuEntryTitle>{{ 'CHANNEL_DETAIL_SETTING_TITLE' | translate }}</span>
        </wen-menu-entry>
      </div>
    </ng-container>
  </ng-container>
</div>
<wen-channel-subscription-controls *ifNotSubscribedTo="currentChannelId$ | async"
  [channelId]="currentChannelId$ | async"
  [restrictions]="restrictions$ | async"
  [inviteData]="inviteData$ | async"
></wen-channel-subscription-controls>