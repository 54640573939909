import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { selectCurrentChannel, selectMessageDetailById } from '../../../../core/store/channel/channel.selectors';
import { combineLatest, first, map, Observable, of, switchMap } from 'rxjs';
import { SocketIoService, UserProfileResponse } from '@portal/wen-backend-api';
import { ChallengeData } from './challenge-user-data-info';

@Injectable()
export class ChallengeContextMenuDatasource {

  constructor(private store: Store, private socketIoService: SocketIoService) {}

  getUserProfileByMessageId(messageId: string): Observable<ChallengeData> {
    return this.store.pipe(
      selectorWithParam(selectMessageDetailById, messageId),
      first(),
      switchMap(message => {
        let profileData$: Observable<Partial<UserProfileResponse>>;
        if (message.authorId) {
          this.socketIoService.user.profile.emit({ userId: message.authorId });
          profileData$ = this.socketIoService.user.profile.listen.pipe(first());
        } else {
          profileData$ = of({displayname: message.userId, enableChatViaProfile: false});
        }

        return combineLatest([
          of(message),
          this.store.pipe(select(selectCurrentChannel)).pipe(first()),
          profileData$
        ]);
      }),
      map(([message, channelData, profileData]) => {
        return {
          messageId: message.id,
          canDirectMessage: profileData.enableChatViaProfile,
          authorUserId: profileData.userId,
          authorName: profileData.displayname,
          channelName: channelData.title
        };
      })
    );
  }
}
