import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteComponent } from './quote.component';
import { RemoveMarkdownModule, WenIconModule } from '@portal/wen-components';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [QuoteComponent],
  exports: [QuoteComponent],
  imports: [
    CommonModule,
    WenIconModule,
    TranslateModule,
    RemoveMarkdownModule
  ]
})
export class QuoteModule { }
