import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

export const CONTEXT_MENU_ID = 'CONTEXT_MENU_ID';

export interface ContextMenuConfig extends OverlayConfig {
  /** ID for the context menu */
  id: string;
}

export class ContextMenuRef {
  /** Unique ID for the context menu */
  readonly id: string;

  readonly menuRef: OverlayRef;

  constructor(
    private readonly overlayRef: OverlayRef,
    private readonly config: ContextMenuConfig
  ) {
    this.id = config.id;
    this.menuRef = overlayRef;
  }
}
