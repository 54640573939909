import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, ViewEncapsulation } from '@angular/core';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { map, ReplaySubject } from 'rxjs';
import { Embed, EmbeddedMedia } from '../../../../../core/store/channel/channel.state';
import { isMediaEmbed } from '../../../../../core/store/channel/util/embed-type-helper';
import { MessageBoxCustomizations } from '../../providers/message-box-embed-customizations';

@Component({
  selector: 'wen-message-box-content',
  templateUrl: './message-box-content.component.html',
  styleUrls: ['./message-box-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBoxContentComponent {

  private messageBoxCustomizations = inject(MessageBoxCustomizations, { optional: true });

  private messageBoxData = new ReplaySubject<any>();
  messageBoxData$ = this.messageBoxData.asObservable().pipe(
    smartDistinctUntilChanged(),
    map((message) => {
      const hasEmbeds = message.embeds?.length > 0;
      return {
        embeds: hasEmbeds ? this.updateEmbeds(message.embeds, message): [],
        forwardedComponent: MessageBoxContentComponent,
        messageId: message.messageId
      };
    })
  );

  textContent$ = new ReplaySubject<string>();

  @HostBinding('class.wen-message-box-content') className = true;

  @Input() set message(value: any) {
    if (value) {
      this.messageBoxData.next(value);
      this.textContent$.next(value.content);
    }
  }

  private updateEmbeds(embeds: Embed[], message: any): Embed[] {
    const otherEmbeds = embeds.filter(embed => !isMediaEmbed(embed));
    let mediaEmbeds = embeds.filter(embed => isMediaEmbed(embed)) as EmbeddedMedia[];
    mediaEmbeds = this.messageBoxCustomizations?.handleMediaEmbeds(mediaEmbeds, message);
    return [...otherEmbeds, ...mediaEmbeds];
  }

}
