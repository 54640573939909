<ng-container *ngIf="headerGravity === 'bottom'; else topHeader">
  <ng-container *ngTemplateOutlet="footer"></ng-container>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ng-container *ngTemplateOutlet="header"></ng-container>
</ng-container>

<ng-template #topHeader>
  <ng-container *ngTemplateOutlet="header"></ng-container>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-template>

<ng-template #content>
  <div class="wen-dialog-layout-content-wrapper">
    <ng-content select="[dialogLayoutContent]"></ng-content>
  </div>
</ng-template>

<ng-template #header>
  <div class="wen-dialog-layout-header-wrapper">
    <ng-content select="wen-dialog-layout-header, [wen-dialog-layout-static-header]"></ng-content>
  </div>
</ng-template>

<ng-template #footer>
  <div class="wen-dialog-layout-header-wrapper">
    <ng-content select="wen-dialog-layout-footer, [wen-dialog-layout-static-footer]"></ng-content>
  </div>
</ng-template>