import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wen-password-visibility-toggle',
  templateUrl: './password-visibility-toggle.component.html'
})
export class PasswordVisibilityToggleComponent implements OnInit {

  @Input() for: HTMLInputElement;

  constructor() { }

  ngOnInit(): void {
  }

  isInPasswordMode() {
    return this.for.type === 'password';
  }

  isInTextMode() {
    return !this.isInPasswordMode();
  }

  toggle(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.isInPasswordMode()) {
      this.for.type = 'text';
    } else if (this.isInTextMode()) {
      this.for.type = 'password';
    }
  }

}
