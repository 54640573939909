import { Component, HostBinding, Optional, ViewEncapsulation, AfterViewInit, OnDestroy, ElementRef, OnInit } from '@angular/core';
import { CoreComponentsModule } from '../../core/core-components.module';
import { AsyncPipe, NgIf } from '@angular/common';
import Hammer from 'hammerjs';
import { map, Observable, BehaviorSubject, merge, Subject } from 'rxjs';
import { VgApiService, VgStates, VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { OverlayVisibilityService } from '../../lightbox-dialog/providers/overlay-visibility.service';
import { VideoHideDelayService } from '../providers/video-hide-delay.service';

@Component({
  selector: 'wen-overlay-controls',
  templateUrl: './overlay-controls.component.html',
  styleUrls: ['./overlay-controls.component.scss'],
  standalone: true,
  imports: [
    CoreComponentsModule,
    NgIf,
    AsyncPipe,
    VgCoreModule,
    VgControlsModule,
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [
    VideoHideDelayService
  ]
})
export class OverlayControlsComponent implements OnInit, AfterViewInit, OnDestroy {

  private hammerManager: HammerManager;
  private videoPlayStatus$ = new BehaviorSubject<VgStates>(this.vgApiService.state);
  private onDestroy$ = new Subject<void>();

  private initialHideDelay = 3000;

  @HostBinding('class.overlay-controls') className = true;
  seekTime = 10;
  isPaused$: Observable<boolean> = merge(
    this.vgApiService.getDefaultMedia().subscriptions.play.pipe(map(() => VgStates.VG_PLAYING)),
    this.vgApiService.getDefaultMedia().subscriptions.ended.pipe(map(() => VgStates.VG_ENDED)),
    this.vgApiService.getDefaultMedia().subscriptions.pause.pipe(map(() => VgStates.VG_PAUSED)),
    this.videoPlayStatus$
  ).pipe(
    map((currentState) => {
      return (currentState === VgStates.VG_PAUSED || currentState === VgStates.VG_ENDED);
    })
  );

  constructor(
    private elRef: ElementRef,
    private videoHideDelayService: VideoHideDelayService,
    @Optional() private vgApiService: VgApiService,
    @Optional() private overlayVisibilityService: OverlayVisibilityService) { }

  ngOnInit(): void {
    this.overlayVisibilityService?.childEvent$?.next(this.initialHideDelay);
  }

  ngAfterViewInit() {
    this.hammerManager = new Hammer.Manager(this.elRef.nativeElement);
    this.hammerManager.on('hammer.input', ({ srcEvent, direction }) => {
      if (direction === Hammer.DIRECTION_HORIZONTAL || direction === Hammer.DIRECTION_NONE) {
        srcEvent.preventDefault();
        srcEvent.stopPropagation();
      }
    });
  }

  seekLeft() {
    this.vgApiService.seekTime(this.vgApiService.currentTime - this.seekTime);
    this.overlayVisibilityService?.childEvent$?.next(this.videoHideDelayService.getHideDelay());
  }

  seekRight() {
    this.vgApiService.seekTime(this.vgApiService.currentTime + this.seekTime);
    this.overlayVisibilityService?.childEvent$?.next(this.videoHideDelayService.getHideDelay());
  }

  play() {
    this.vgApiService.play();
    this.videoPlayStatus$.next(VgStates.VG_PLAYING);
    this.overlayVisibilityService?.childEvent$?.next(this.videoHideDelayService.playingHideDelay);
  }

  pause() {
    this.vgApiService.pause();
    this.videoPlayStatus$.next(VgStates.VG_PAUSED);
    this.overlayVisibilityService?.childEvent$?.next(this.videoHideDelayService.pausedHideDelay);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
