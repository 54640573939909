import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { LoadResultMode } from '../../../../../core/common/types/misc';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { selectDiscoverChannelIsLoaded, selectDiscoverChannels } from '../../../../../core/store/channel/channel.selectors';
import { selectActiveFiltersByEntityType } from '../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../core/store/root/public-api';
import { ChannelLoadResult } from '../channel-list/models/channel-load-result';
import { ChannelListItemFactory } from '../channel-list/channel-list-item/providers/channel-list-item-factory';
import { filterBy } from '../../../../../core/common/operators/fitler-by';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { ChannelListItemDataSource } from '../channel-list/channel-list-item/providers/channel-list-item-data-source';

@Injectable()
export class DiscoverChannelListDataSource {

  public readonly loadResult$: Observable<ChannelLoadResult>;

  constructor(
    private readonly store: Store<RootState>,
    private readonly itemFactory: ChannelListItemFactory,
    private readonly itemDataSource: ChannelListItemDataSource
  ) {

    const isDiscoverChannelsLoaded$ = this.store.pipe(
      select(selectDiscoverChannelIsLoaded),
      distinctUntilChanged(),
    );

    const loadResultMode$ = this.store.pipe(
      selectorWithParam(selectActiveFiltersByEntityType, FilterEntityType.CHANNEL_LISTS),
      map(filters => filters?.length ? LoadResultMode.SEARCH : LoadResultMode.NORMAL),
      distinctUntilChanged(),
    );

    const discoverChannels$: Observable<string[]> = this.store.pipe(
      filterBy(() => isDiscoverChannelsLoaded$),
      select(selectDiscoverChannels),
      map(channelArray => channelArray.map(channel => channel.id)),
      startWith([]),
      smartDistinctUntilChanged(),
      shareReplay(1),
    );

    const allChannelModels$ = discoverChannels$.pipe(
      switchMap(channelIds => {
        const models$ = channelIds.map(id => this.itemDataSource.getChannelListItemProps(id).pipe(
          map(properties => {
            if (!Boolean(properties)) {
              return null;
            }
            if (properties.channel.invite) {
              return this.itemFactory.createInvitationListItem(properties, true);
            }
            return this.itemFactory.createSimpleListItem(properties);
          }),
          smartDistinctUntilChanged()
        ));
        return combineLatest(models$);
      }),
      startWith([]),
      shareReplay(1)
    );

    const filteredChannelModels$ = allChannelModels$.pipe(
      map(channels => channels.filter(Boolean))
    );

    this.loadResult$ = isDiscoverChannelsLoaded$.pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return of({ mode: LoadResultMode.NORMAL, data: null });
        }
        return filteredChannelModels$.pipe(
          withLatestFrom(loadResultMode$),
          map(([data, mode]) => ({ mode, data }))
        );
      }),
      smartDistinctUntilChanged(),
    );
  }

}
