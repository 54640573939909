import { VgApiService, VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { filter, first, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { MuteComponent } from './custom-controls/mute/mute.component';
import { OverlayControlsComponent } from './overlay-controls/overlay-controls.component';
import { OverlayVisibilityService } from '../lightbox-dialog/providers/overlay-visibility.service';
import { fadeInOutAnimation } from '../../animations/fade-in-out-animation';
import { VideoControlsComponent } from './video-controls/video-controls.component';

@Component({
  selector: 'wen-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  standalone: true,
  imports: [
    VgCoreModule,
    VgControlsModule,
    NgIf,
    AsyncPipe,
    OverlayControlsComponent,
    VideoControlsComponent,
    MuteComponent
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOutAnimation('150ms', 'ease-in')]
})
export class VideoPlayerComponent implements OnDestroy {

  private onDestroy$ = new Subject<void>();
  private api: VgApiService;

  controlsVisible$: Observable<boolean>;

  @ViewChild('videoRef') set videoEl(videoElement: ElementRef<HTMLVideoElement>) {
    if (videoElement) {
      /** On iOS this is the reliable event which can be used to initiate play of the video. Unfortunately this is not supported properly
       * by the underlying API.
       */
      fromEvent(videoElement.nativeElement, 'loadedmetadata').pipe(
        first(),
        filter(() => !!this.api)
      ).subscribe(() => {
        this.api.play();
      });
    }
  }

  @HostBinding('class.video-player') className = true;

  @Input() src: string;

  isVisible$: Observable<boolean>;

  constructor(@Optional() private overlayVisibility: OverlayVisibilityService) {
    this.controlsVisible$ = this.overlayVisibility.isVisible$;
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.ended.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.api.currentTime = 0);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
