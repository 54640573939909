<wen-list-item class="wen-list-item-auto-height"
  *ngIf="model.locationData | locationFormatter: 'multiline'; let formattedLocation" [justifyInfoContent]="'center'"
  [showAfter]="true">
  <wen-icon class="wen-icon-huge wen-icon-circular" [wenVariant]="'secondaryFlat'" wen-list-item-icon
    [iconName]="model.locationData | locationIconClassifier"></wen-icon>
  <span wen-list-item-title [innerHtml]="formattedLocation.line1 | highlight: searchTerm"></span>
  <span *ngIf="formattedLocation.line2" wen-list-item-description>{{ formattedLocation.line2 }}</span>
  <ng-container wen-list-item-after>
    <wen-icon class="wen-location-list-item-after wen-icon-medium" (click)="onPrefillActionClicked($event)" [wenVariant]="'secondaryInlined'"
      iconName="arrow_left_up"></wen-icon>
  </ng-container>
</wen-list-item>