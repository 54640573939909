<ng-container *ngIf="count$ | async as count; else defaultView">
  <wen-icon iconName="comment_active" [class.disabled]="disabled"></wen-icon>
  <div class="wen-create-comment-button-text" [class.disabled]="disabled">
    {{ count }}
  </div>
</ng-container>

<ng-template #defaultView>
  <wen-icon [class.disabled]="disabled || deactivated" iconName="comment_inactive"></wen-icon>
  <div [class.disabled]="disabled || deactivated" class="wen-create-comment-button-text">
    {{ (deactivated ? "ADD_COMMENTS_DEACTIVATED_LABEL" : "ADD_COMMENTS_LABEL") | translate }}
  </div>
</ng-template>
