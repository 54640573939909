<form wenFormStore class="wen-form" [formGroup]="userVisibilityForm">
  <ng-container *ngIf="currentUser$ | async">
    <ng-container *ngIf="chatEnabled">
      <div class="wen-form-group" *ngIf="initialSelection">
        <div class="wen-form-field-wrapper">
          <div class="wen-form-field-label" portal-caption>{{ 'USER_VISIBILITY_SETTINGS_CONTACT_LABEL' | translate }}</div>
          <wen-user-visibility-selection-list [selectionModel]="initialSelection" [selectionItems]="visibilityItems" (activeSelection)="onSelectionChange($event)"></wen-user-visibility-selection-list>
        </div>
        <div class="wen-form-group-hint" portal-subheading-2>{{ 'USER_VISIBILITY_SETTINGS_CONTACT_HINT' | translate }}</div>
      </div>
    </ng-container>

    <div class="wen-form-group">
      <div class="wen-form-field-wrapper">
        <div class="wen-form-field-label" portal-caption>{{ 'USER_VISIBILITY_SETTINGS_CUSTOM_DISCOVERABILITY_LABEL' | translate }}</div>
        <div class="wen-form-field wen-toggle-field">
          <div>{{ "USER_VISIBILITY_SETTINGS_DISCOVERABILITY_FINDABLE_TOGGLE_NAME" | translate }}</div>
          <mat-slide-toggle color="primary" formControlName="findable"></mat-slide-toggle>
        </div>
        <ng-container *ngIf="findable$ | async">
          <div class="wen-form-field wen-toggle-field">
            <div>{{ "USER_VISIBILITY_SETTINGS_DISCOVERABILITY_NUMBER_TOGGLE_LABEL" | translate }}</div>
            <mat-slide-toggle color="primary" formControlName="searchableByPhone"></mat-slide-toggle>
          </div>
          <div class="wen-form-field wen-toggle-field">
            <div>{{ "USER_VISIBILITY_SETTINGS_DISCOVERABILITY_NAME_TOGGLE_LABEL" | translate }}</div>
            <mat-slide-toggle color="primary" formControlName="searchableByName"></mat-slide-toggle>
          </div>
        </ng-container>
      </div>
      <div class="wen-form-group-hint" portal-subheading-2>{{ 'USER_VISIBILITY_SETTINGS_FINDABLE_HINT' | translate }}</div>
    </div>
  </ng-container>
</form>
