import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TouchEndListenerModule } from '../../directives/touch-end-listener/touch-end-listener.module';
import { WenTestIdModule } from '../../directives/test-id/test-id.module';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { ActionMenuComponent } from './action-menu.component';

@NgModule({
  declarations: [ActionMenuComponent],
  imports: [
  CommonModule,
    WenIconModule,
    WenTestIdModule,
    TouchEndListenerModule
  ],
  exports: [ActionMenuComponent]
})
export class ActionMenuModule {

}
