import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IsCurrentUserModule } from '../../shared/directives/is-current-user/is-current-user.module';
import { ScrollStateModule } from '../../shared/directives/scroll-state/scroll-state.module';
import { SharedModule } from '../../shared/shared.module';
import { ChatListItemComponent } from './chat-list/components/chat-list-item/chat-list-item.component';
import { UserChatListComponent } from './chat-list/components/user-chat-list/user-chat-list.component';
import { ChatViewModule } from './chat-view/chat-view.module';
import { ChatRootGuard } from './routes/chat-root.guard';
import { ChatDetailModule } from './chat-detail/chat-detail.module';
import { MessageStatusIndicatorModule } from '../../shared/components/message-status-indicator/message-status-indicator.module';
import { ACTION_MENU_ITEMS, ActionMenuModule, ContextMenuTriggerDirective, ContextMenuComponent, WenOverlayModule } from '@portal/wen-components';
import { CHAT_LIST_ITEM_CONTEXT_MENU_ITEMS } from './chat-list/components/chat-list-item/action-menu/chat-list-item.actions';
import { ChatEditModeModule } from './chat-creation/chat-edit-mode.module';
import { ChatSchedulesModule } from './chat-schedules/chat-schedules.module';
import { UnencryptedChatDialogOpenerService } from './common/unencrypted-chat-dialog/unencrypted-chat-dialog-opener';

@NgModule({
  imports: [
    SharedModule,
    ChatViewModule,
    TranslateModule,
    IsCurrentUserModule,
    ChatEditModeModule,
    ChatSchedulesModule,
    ScrollingModule,
    ScrollStateModule,
    ChatDetailModule,
    MessageStatusIndicatorModule,
    ActionMenuModule,
    ContextMenuTriggerDirective,
    ContextMenuComponent,
    WenOverlayModule,
  ],
  providers: [
    ChatRootGuard,
    { provide: ACTION_MENU_ITEMS, useValue: CHAT_LIST_ITEM_CONTEXT_MENU_ITEMS, multi: true },
    UnencryptedChatDialogOpenerService
  ],
  declarations: [
    UserChatListComponent,
    ChatListItemComponent
  ],
})
export class ChatModule { }
