import { EmbeddedForwardDTO, EmbeddedLinkDTO } from '@portal/wen-backend-api';
import { EmbeddedMedia, EmbeddedPoll } from '../channel/channel.state';

export enum PendingEmbedType {
  UNSAFE = 'unsafe',
  SAFE = 'safe'
}

export interface PendingEmbed {
  pendingType: PendingEmbedType;
}

export interface Quote {
  quotedCommentId?: string;
  quotedAuthor?: string;
  quotedText?: string;
}

export interface DraftMessageEntity {
  contextId: string;
  id?: string;
  content?: string;
  linkEmbed?: EmbeddedLinkDTO;
  fileEmbed?: EmbeddedMedia;
  pollEmbed?: EmbeddedPoll;
  forwardEmbed?: EmbeddedForwardDTO;
  timestamp?: string;
  scheduled?: boolean;
  quote?: Quote;
}
