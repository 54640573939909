<wen-message-discard-edit-button></wen-message-discard-edit-button>
<wen-media-embed-preview></wen-media-embed-preview>
<wen-message-send-field
  [inputPlaceholder]="'SEND_MESSAGE_INPUT_PLACEHOLDER' | translate"
  [contextId]="roomId$ | async"
  [keepInputFocused]="keepInputFocused"
  (messageSent)="onMessageSent()"
  (focused)="onFocused()"
  [isSendingAllowed]="isSendingAllowed$ | async"
  >
  <wen-message-type-selector
    wen-message-type-selector
    (tap)="onTap()"
    [menuId]="messageSelectorId"
    [disabled]="hasMediaEmbed$ | async">
  </wen-message-type-selector>
  <wen-chat-send-scheduled-message
    *ngIf="isChatScheduledSendingAllowed$ | async"
    wen-message-left-button-action
  ></wen-chat-send-scheduled-message>
  <wen-navigate-chat-scheduled-messages-view-button
    *ngIf="isChatScheduledNavigationAllowed$ | async"
    [roomId]="roomId$ | async"
    wen-message-right-button-action
  ></wen-navigate-chat-scheduled-messages-view-button>
</wen-message-send-field>
