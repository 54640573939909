<div class="wen-widget-content" (contextmenu)="onContextMenu($event)" [class.wen-widget-content-wide]="isWideDevice">
  <div class="wen-banner-container" [class.wen-wide-banner-container]="isWideDevice">
    <wen-app-banner *ngIf="isAppBannerVisible$ | async" [smartStoreButtons]="isWideDevice === false" 
      (closed)="onAppBannerClosed()"></wen-app-banner>
    <wen-refresh-banner *ngIf="showRefreshBanner$ | async"></wen-refresh-banner>
  </div>
  <ng-container *ngIf="isHeaderVisible$ | async">
    <ng-content select="wen-header"></ng-content>
  </ng-container>
  <ng-container *ngIf="isWideDevice; else mobileContent">
    <ng-container *ngTemplateOutlet="desktopContent"></ng-container>
  </ng-container>
</div>

<ng-template #desktopContent>
  <div class="wen-desktop-content">
    <div class="wen-desktop-sidebar" *ngIf="showSidebar$ | async">
      <div class="wen-desktop-sidebar-header" *ngIf="isNavigationBarVisible$ | async">
        <ng-container>
          <ng-container *ngTemplateOutlet="navbarTemplate"></ng-container>
          <wen-search-bar-desktop *ngIf="searchConfig$ | async; let searchConfig"
            [searchBarPlaceholder]="searchConfig.searchBarPlaceholder"
            [searchEventsHandler]="searchConfig"
            [disabled]="searchConfig?.disabled"
            [filter-element]="searchConfig?.filterConfig"
            [filterId]="searchConfig?.filterConfig?.filterId"
            [filterEntityType]="searchConfig?.filterConfig?.filterEntityType"
          ></wen-search-bar-desktop>
        </ng-container>
      </div>
      <div class="wen-desktop-sidebar-outlet">
        <router-outlet name="sidebar"></router-outlet>
      </div>
    </div>
    <div class="wen-desktop-primary-outlet">
      <ng-container *ngTemplateOutlet="primaryOutlet"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #mobileContent>
  <div class="wen-mobile-content" wenIosSwipeDetector (swipeEvent)="swipeEvent($event)">
    <div [@routeAnimations]="prepareRouteForAnimation(outlet)" class="wen-mobile-primary-outlet">
      <router-outlet #outlet="outlet">
      </router-outlet>
    </div>
    <div *ngIf="isNavigationBarVisible$ | async" class="wen-mobile-navigation-bar">
      <ng-container *ngTemplateOutlet="navbarTemplate"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #navbarTemplate>
  <ng-content select="wen-navigation-bar"></ng-content>
</ng-template>

<ng-template #primaryOutlet>
  <router-outlet>
  </router-outlet>
</ng-template>