<div class="wen-channel-scheduled-messages-content" portalDragOverlay [overlayContent]="overlayContent"
  [dragOverlayEnabled]="(isMessageInputVisible$ | async)" (fileDropped)="onFilesDropped($event)">
  <wen-channel-messages-view [flowDirection]="'down'" [disableScrollToBottomButton]="true">
    <wen-we-feed-empty-placeholder
      [text]="'EMPTY_CHANNEL_SCHEDULED_MESSAGES_LABEL' | translate"
      [imageUrl]="'/assets/wen-widget/image/pic-no-Scheduled_messages.svg'">
    </wen-we-feed-empty-placeholder>
  </wen-channel-messages-view>
</div>
<div class="wen-channel-schedules-bottom-controls" *ngIf="isMessageInputVisible$ | async">
  <div class="wen-channel-schedules-edit-wrapper">
    <wen-channel-schedule-message-send-field></wen-channel-schedule-message-send-field>
  </div>
</div>