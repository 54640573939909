import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, QueryList, ViewChildren } from '@angular/core';
import { ActionMenuItem } from './public-api';

@Component({
  selector: 'wen-action-menu',
  templateUrl: './action-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent<D> {

  @HostBinding('class.wen-action-menu-content') className = true;

  @ViewChildren('actionMenuItem', { read: ElementRef }) actionMenuItems: QueryList<ElementRef>;

  @Input() actions: ActionMenuItem[] = [];
  @Input() data: D;

}
