<div class="wen-form">
  <div class="wen-form-group wen-single-group">
    <div class="wen-form-field-label" portal-caption>{{ 'CHANNEL_ADMIN_LIST_LABEL' | translate }}</div>
      <div class="wen-channel-admin-list-add-admin-button" *ngIf="canAddAdmins$ | async" portal-body-2 wen-primary-color (click)="navigateToAddAdminPage()">
        <wen-icon class="wen-icon-big" [iconName]="'add_user'"></wen-icon>
        {{ "CHANNEL_ADD_ADMIN_TITLE" | translate }}
      </div>
    <div class="wen-form-field-scroller">
      <wen-user-list-item *ngFor="let listItem of adminList$ | async" [user]="listItem.user" [userNavigable]="listItem.user.userId">
        <wen-icon *ngIf="listItem.hasMenu" [iconName]="'more'" [color]="contextMenuOpenerFillColor" [context-menu-trigger]="menu"
          wen-user-after></wen-icon>
        <wen-context-menu #menu>
          <wen-action-menu [actions]="actions$ | async" [data]="listItem.payload"></wen-action-menu>
        </wen-context-menu>
      </wen-user-list-item>
    </div>
    <div class="wen-form-group-hint" portal-subheading-2>{{ 'CHANNEL_ADMIN_LIST_DESCRIPTION_LABEL' | translate }}</div>
  </div>
</div>
