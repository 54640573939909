import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { initializeAppFilters, updateAppCategories } from '../apps.actions';
import { first, switchMap } from 'rxjs';
import { createFilterableFromCategory } from '../../../../shared/components/filter-view/models/category';
import { SectionEntity } from '../../sections/sections.state';
import { FilterEntityType } from '../../filter/models/filter';
import { updateSection } from '../../sections/sections.actions';

@Injectable()
export class AppFiltersEffects {

  initializeAppCategoryFilters$ = createEffect(() => this.action$.pipe(
    ofType(initializeAppFilters),
    first(),
    switchMap(() => {
      return this.socketIoService.app.getCategories.acknowledgement$();
    }),
    switchMap((categories) => {
      const appCategoriesAsSections: SectionEntity[] = categories.map(category => {
        const categoryAsFilter = createFilterableFromCategory('categories', category);
        return {
          id: category.id,
          name: category.name,
          filterRef: categoryAsFilter,
          filterType: FilterEntityType.APP_LISTS
        };
      });
      return [
        updateAppCategories({ categories }),
        updateSection({ sections: appCategoriesAsSections })
      ];
    })
  ));

  constructor(
    private action$: Actions,
    private socketIoService: SocketIoService,
  ) { }

}
