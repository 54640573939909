<ng-container *ngIf="sectionItems$ | async as sectionItems">
  <wen-section *ngIf="sectionItems.length" [isNativeApp]="isNativeApp">
    <div *ngIf="sectionName" section-header-title>{{ sectionName | translate }}</div>
    <div class="wen-section-show-all-title" section-header-action (click)="showAllElements()"
      [wen-test-id]="'wen-app-explore-section-show-secton-button'">{{ 'SHOW_ALL_LABEL' | translate }}</div>
    <wen-card class="wen-card-narrow" *ngFor="let item of sectionItems" [direction]="'column'" [elevate]="true"
      (click)="navigateToAppDetail(item)" section-content [wen-test-id]="'wen-app-explore-section-card'">
      <wen-circle-image-view [imageUrl]="item.icon" shape="square" [placeholderText]="item.title"
        [imageSize]="imageSize" card-image></wen-circle-image-view>
      <div card-title>{{item.title}}</div>
      <div card-description>{{ item.categories[0].name }}</div>
      <wen-quick-action-panel class="wen-icon-medium wen-app-downloaded-indicator"
        [iconName]="item.isSubscribed ? 'download_active' : 'download_inactive'" card-floating-panel
        (quickActionTriggered)="toggleAppSubscription(item.id, !item.isSubscribed)"></wen-quick-action-panel>
    </wen-card>
    <wen-card *ngIf="sectionItems.length === sectionLimit" class="last-card wen-card-narrow" [elevate]="true"
      section-content (click)="showAllElements()">
      <div card-title>
        <span wen-primary-color>{{ 'SHOW_ALL_LABEL' | translate }}</span>
      </div>
    </wen-card>
  </wen-section>
</ng-container>