import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { WenBreakpointObserver } from '@portal/wen-components';
import { Observable, Subject } from 'rxjs';
import { FilterId } from '../../../../../core/common/types/filter-id';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { reloadDiscoverAppList } from '../../../../../core/store/apps/apps.actions';
import { AppEntity } from '../../../../../core/store/apps/apps.state';
import { RootState } from '../../../../../core/store/root/public-api';
import { AppListLoadResult } from '../../models/AppLoadResult';
import { AppListHeaderDataUpdater } from '../../providers/app-list-header-data-updater';
import { DiscoverAppListDataSource } from './discover-app-list-data-source';
import { AppCategoryFilterDataSource } from './filter-sources/app-category-filter-data-source';
import { Filterable } from '../../../../../shared/components/filter-view/models/filter-item';
import { Filter, FilterEntityType } from '../../../../../core/store/filter/models/filter';

@Component({
  selector: 'wen-discover-app-list',
  templateUrl: './discover-app-list.component.html',
  styleUrls: ['./discover-app-list.component.scss'],
  providers: [
    DiscoverAppListDataSource, AppListHeaderDataUpdater, AppCategoryFilterDataSource
  ]
})
export class DiscoverAppListComponent implements OnInit, OnDestroy {

  @HostBinding('class.wen-discover-app-list') className = true;
  private onDestroy$ = new Subject<void>();

  loadResult$: Observable<AppListLoadResult>;
  isWideDevice = this.breakpointObserver.isDesktopStyleDevice;
  isFilteringEnabled = this.featureEnablementService.featureFlagMethods.isEnableFiltering();

  filterEntityType = FilterEntityType.APP_LISTS;
  appCategoryControlName = FilterId.APP_CATEGORIES;
  filterGroup = new FormGroup({
    [this.appCategoryControlName]: new FormControl(),
  });
  get appCategoryControl() {
    return this.filterGroup.controls[this.appCategoryControlName];
  }

  constructor(
    private readonly store: Store<RootState>,
    private readonly dataSource: DiscoverAppListDataSource,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly breakpointObserver: WenBreakpointObserver,
    private readonly featureEnablementService: FeatureEnablementService,
    private readonly appListHeaderDataUpdater: AppListHeaderDataUpdater,
    public readonly categoryFilterService: AppCategoryFilterDataSource,
  ) { }

  ngOnInit() {
    this.appListHeaderDataUpdater.initialize();

    this.loadResult$ = this.dataSource.loadResult$;
    this.store.dispatch(reloadDiscoverAppList());

    this.categoryFilterService.currentActiveAppCategory$.subscribe((activeAppCategoryFilter: Filter) => {
      this.appCategoryControl.setValue(activeAppCategoryFilter?.filterValue);
    });
  }

  onAppClick(item: AppEntity) {
    this.navigationHelper.navigateToAppDetail(item.id);
  }

  openAppCategoryFilterSelector() {
    const activeCategory: Filterable = this.appCategoryControl.value;
    this.categoryFilterService.openFilterSelector(activeCategory).subscribe((newValue) => {
      this.appCategoryControl.setValue(newValue);
      this.store.dispatch(reloadDiscoverAppList());
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
