<div class="wen-home-container">
  <div class="wen-home-cover" [wen-test-id]="'wen-home-cover'">
    <div class="wen-welcome-text" [wen-test-id]="'wen-home-welcome-title'">
      <div portal-display-1>{{ 'HOME_PAGE_WELCOME_TITLE' | translate }}</div>
      <div portal-display-2>{{ 'HOME_PAGE_WELCOME_TITLE_APP' | translate }}</div>
    </div>
    <img class="wen-home-background" [src]="homeCoverImage$ | async"/>
  </div>
  <div class="wen-home-featured" *ngIf="featuredData$ | async; let featuredData">
    <wen-message-preview-card (click)="featuredCardClicked()" [context]="featuredData" [wen-test-id]="'wen-home-featured-message-card'"></wen-message-preview-card>
  </div>

  <ng-container *ngFor="let section of featuredSectionConfigs  | slice:0:2; let i = index">
    <wen-generic-section [config]="section" class="wen-home-section" [wen-test-id]="'wen-generic-section-' + i"></wen-generic-section>
  </ng-container>

  <div class="wen-home-weather" [wen-test-id]="'wen-home-weather'" *ngIf="weatherData$ | async as weatherData">
    <wen-weather
      [model]="weatherData"
      [ngClass]="{ 'wen-interactive': canNavigateOnWeatherWidget }"
      (click)="weatherClicked()">
    </wen-weather>
  </div>

  <div *ngIf="buttonSectionLink" class="wen-home-button-section">
    <div class="wen-home-text wen-home-button-caption">{{ 'HOME_BUTTON_SECTION_DESCRIPTION' | translate }}</div>
    <wen-rounded-button (click)="buttonSectionClicked()">
      <span>{{ 'HOME_BUTTON_SECTION_LABEL' | translate }}</span>
    </wen-rounded-button>
  </div>

  <ng-container *ngFor="let section of featuredSectionConfigs | slice:2; let i = index">
    <wen-generic-section [config]="section" class="wen-home-section" [wen-test-id]="'wen-generic-section-' + (i + 2)"></wen-generic-section>
  </ng-container>
</div>
