<ng-container *ngIf="previewData">
  <div class="wen-link-embed-preview-wrapper">
    <div class="wen-link-embed-preview">
      <wen-thumbnail-image *ngIf="previewData.thumbnail" [thumbnail]="previewData.thumbnail" [fixWidth]="fixWidth"></wen-thumbnail-image>
      <div class="wen-link-embed-preview-embedded-wrapper">
        <div *ngIf="previewData.title" portal-title class="wen-link-embed-preview-embed-title">
          {{ previewData.title }}
        </div>
        <div *ngIf="previewData.description" portal-subheading-1 class="wen-link-embed-preview-embed-description">
          {{ previewData.description }}
        </div>
      </div>
    </div>
    <wen-icon class="wen-link-embed-preview-dismiss" [class.wen-icon-huge]="isFilledDismissIcon" wen-primary-color (click)="dismissLinkPreview()" iconName="close"></wen-icon>
  </div>
</ng-container>