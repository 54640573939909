<div class="wen-app-banner-content">
  <wen-icon class="app-banner-close-button" [iconName]="'close'" [color]="'white'" (click)="emitClosed()"></wen-icon>
  <div class="wen-app-banner-info">
    <img class="wen-app-banner-logo" [src]="'/assets/wen-widget/image/we-logo.png'">
    <div class="wen-app-banner-info-text">
      <h2>{{ "APP_BANNER_INFO_HEADING" | translate }}</h2>
      <h3>{{ "APP_BANNER_INFO_SUBHEADING" | translate }}</h3>
    </div>
  </div>
  <div class="wen-app-banner-buttons-wrapper" *ngIf="smartStoreButtons; else splitStoreButtons">
    <wen-interact-button class="wen-app-banner-to-app-button wen-interact-button-round" (click)="goToApp()">
      <h2>{{ "APP_BANNER_TO_APP" | translate }}</h2>
    </wen-interact-button>
  </div>
  <ng-template #splitStoreButtons>
    <div class="wen-app-banner-buttons-wrapper">
      <wen-interact-button *ngIf="showPlayStoreButton" class="wen-app-banner-to-app-button wen-interact-button-round" (click)="goToPlayStore()">
        <h2>{{ "APP_BANNER_TO_APP_ANDROID" | translate }}</h2>
      </wen-interact-button>
      <wen-interact-button *ngIf="showAppStoreButton" class="wen-app-banner-to-app-button wen-interact-button-round" (click)="goToAppStore()">
        <h2>{{ "APP_BANNER_TO_APP_IOS" | translate }}</h2>
      </wen-interact-button>
    </div>
  </ng-template>
</div>