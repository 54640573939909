import { CdkScrollable } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, Inject, Input, QueryList, ViewChildren
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import {
  distinctUntilChanged,
  from,
  groupBy,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  toArray
} from 'rxjs';
import { MessageSeparatorData } from '../../directives/directives/separator-factory';
import { MessagesDatasource } from '../../services/messages.datasource';
import { CommentComponent } from './comment/comment.component';
import { CommentListDataSource } from './providers/comment-list-data-source';
import { CommentListPagingHelper } from './providers/comment-list-paging-helper';
import { WenComment } from './types';
import { ScrollerWrapper } from '../../../core/services/scrolling/scroller-wrapper';
import { RootState } from '../../../core/store/root/public-api';
import { Store } from '@ngrx/store';
import { updateDraftComment } from '../../../core/store/comments/comments.actions';
import { ActionMenuItemProvider } from '../../../core/services/util/action-menu-item.provider';
import { commentContextMenuId } from './actions/comment-action-menu';
import { ActionMenuItem, ReactionSelectorData } from '@portal/wen-components';
import { ReactionContext } from '@portal/wen-backend-api';

interface CommentListContext {
  comments: {
    comment: WenComment;
    actions: Observable<ActionMenuItem>[];
    reactionData: ReactionSelectorData;
  };
  dateSeparator: MessageSeparatorData;
}

@Component({
  selector: 'wen-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  providers: [
    {
      provide: MessagesDatasource,
      useClass: CommentListDataSource
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentListComponent implements AfterViewInit {

  emptyCommentListSeparatorContext: MessageSeparatorData = {
    customText: this.translateService.instant('EMPTY_COMMENTS_PLACEHOLDER')
  };

  groupedCommentList$: Observable<CommentListContext[] | any>;

  hasMoreToLoad$: Observable<boolean>;

  private innerMessageId: string;

  protected readonly ReactionContext = ReactionContext;

  @ViewChildren('commentElement') private commentElements: QueryList<CommentComponent>;

  @Input()
  set messageId(messageId: string) {
    this.innerMessageId = messageId;
    this.commentListDataSource.initialize(messageId);
    this.commentListPagingHelper.initialize(messageId);
    this.groupedCommentList$ = this.commentListDataSource.messages$.pipe(
      distinctUntilChanged(),
      switchMap(comments => {
        if (comments?.length) {
          return from(comments).pipe(
            groupBy(comment => this.dateOfCreation(comment)),
            mergeMap(group$ => group$.pipe(toArray())),
            map((data: WenComment[]) => {
              return {
                dateSeparator: {
                  current: data[0].createTimestamp,
                  newMessageLine: false
                },
                comments: data.map(comment => ({
                  comment,
                  reactionData: {
                    reactionParentId: comment.id,
                    reactionParentType: ReactionContext.COMMENT
                  },
                  actions: this.actionMenuItemProvider.selectActions(commentContextMenuId, { comment, messageId })
                }))
              };
            }),
            toArray()
          );
        }
        return of(false);
      })
    );
    this.hasMoreToLoad$ = this.commentListPagingHelper.currentPagingInformation$.pipe(
      distinctUntilChanged(),
      map(pagingInformation => !pagingInformation.last)
    );
  }

  get messageId(): string {
    return this.innerMessageId;
  }

  constructor(
    @Inject(MessagesDatasource) private commentListDataSource: CommentListDataSource,
    private translateService: TranslateService,
    private cdkScrollable: CdkScrollable,
    private commentListPagingHelper: CommentListPagingHelper,
    private scrollerWrapper: ScrollerWrapper,
    private store: Store<RootState>,
    private actionMenuItemProvider: ActionMenuItemProvider
  ) { }

  ngAfterViewInit(): void {
    this.commentListPagingHelper.connect(this.cdkScrollable);
  }

  loadMoreComments() {
    this.commentListPagingHelper.loadNextChunkOfComments(this.innerMessageId);
  }

  private dateOfCreation(comment: WenComment): string {
    return DateTime.fromISO(comment.createTimestamp).toSQLDate();
  }

  scrollToQuoteOrigin(originalCommentId: string) {
    if (this.commentElements) {
      const originalComment = this.commentElements
        .find(element => {
          return element.comment.id === originalCommentId;
        });

      if (originalComment) {
        const offsetRef = originalComment?.commentBubble?.nativeElement?.offsetParent?.parentElement;
        this.scrollerWrapper.scrollTo({ top: offsetRef?.offsetTop - 5 });
        this.highlight(originalComment);
      }
    }
  }

  private highlight(comment: CommentComponent) {
    if (comment.isHighlighted) {
      return;
    }

    comment.isHighlighted = true;
    setTimeout(() => {
      comment.isHighlighted = false;
    }, 1000);
  }

  onSwipeQuoted(event: { comment: CommentComponent }) {
    this.store.dispatch(updateDraftComment({
      comment: {
        quote: {
          quotedCommentId: event.comment.comment.id,
          quotedAuthor: event.comment.comment.userName,
          quotedText: event.comment.comment.text
        }
      }
    }));
  }
}
