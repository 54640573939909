import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wen-navigate-to-channel-button',
  templateUrl: './navigate-to-channel-button.component.html',
  styleUrls: ['./navigate-to-channel-button.component.scss']
})
export class NavigateToChannelButtonComponent implements OnInit {

  @Input() description: string;
  @Input() image: string;

  @Output() clicked = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.clicked.emit();
  }

}
