<ng-container *ngIf="loadResult$ | async; let loadResult">
  <ng-container *ngIf="loadResult.data">
    <ng-container *ngIf="loadResult.data.length; else emptyList">
      <ng-container *ngTemplateOutlet="exploreChannelHeader">
      </ng-container>
      <wen-channel-list [channelList]="loadResult.data" [showAfterElement]="true" [wen-test-id]="'wen-subscribed-channel-list'" [enableContextMenu]="true"></wen-channel-list>
    </ng-container>
    <ng-template #emptyList>
      <wen-empty-list
        *ngIf="loadResult.mode === 'normal'; else searchMode"
        src="/assets/wen-widget/image/empty-channel-list-background.svg"
        titleLabel="CHANNEL_LIST_EMPTY_CHANNEL_TITLE"
        textLabel="CHANNEL_LIST_EMPTY_CHANNEL_TEXT"
        [wen-test-id]="'wen-user-empty-list'"
      >
        <ng-container wen-empty-list-header>
          <ng-container *ngTemplateOutlet="exploreChannelHeader"></ng-container>
        </ng-container>
      </wen-empty-list>
      <ng-template #searchMode>
        <wen-empty-search-result></wen-empty-search-result>
      </ng-template>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #exploreChannelHeader>
  <div class="wen-explore-header">
    <div class="wen-explore-header-label">{{ 'CHANNELS_HEADER_TITLE' | translate }}</div>
    <div class="wen-user-channel-list-explore-channel-button" portal-body-2 wen-primary-color
      [wen-test-id]="'channel-my-list-explore-channel-button'"
      (click)="navigateToChannelExplore()">
      <wen-icon [iconName]="'compass'" color="primary"></wen-icon>
      {{ "CHANNEL_SHOW_MORE" | translate }}
    </div>
  </div>
</ng-template>
