<wen-confirmation-dialog>
  <div wen-confirmation-dialog-custom-content>
    <p> {{"UNENCRYPTED_CHAT_DIALOG_CONTENT.0" | translate}} </p>
    <wen-markdown-text [content]="'UNENCRYPTED_CHAT_DIALOG_CONTENT.1' | translate: { contactMail: supportMail }"></wen-markdown-text>
    <div class="checkbox-container">
      <mat-checkbox [(ngModel)]="doNotShowAgainSelected" [disableRipple]="'true'">
        {{ "DIALOG_DO_NOT_SHOW_AGAIN_LABEL" | translate }}
      </mat-checkbox>
    </div>
  </div> 
  <button wen-confirmation-dialog-ok-action class="wen-confirmation-dialog-wide-action" (click)="onAccept()">
  {{ "UNENCRYPTED_CHAT_DIALOG_OK_LABEL" | translate }}
  </button> 
</wen-confirmation-dialog>