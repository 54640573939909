<ng-container *ngIf="isWeLocalPlayerNeeded; else newPlayer">
  <iframe [wenLazyload]="sourceUrl" allowfullscreen></iframe>
</ng-container>

<ng-template #newPlayer>
  <ng-container *ngIf="canUseNewVideoPlayer; else videoTag">
    <div class="wen-embed-overlay" (tap)="onMediaClick()">
      <wen-button class="wen-button-round" wenVariant="icon" iconName="play" iconSize="huge"></wen-button>
    </div>
    <img #image [wenLazyload]="thumbnail$ | async" retry-image-load/>
  </ng-container>
</ng-template>

<ng-template #videoTag>
  <video [poster]="thumbnail$ | async" preload="metadata" controls playsInline>
    <source [src]="sourceUrl" />
  </video>
</ng-template>
