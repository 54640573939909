import { Component, ElementRef, Input } from '@angular/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { NgClass, NgIf } from '@angular/common';

export enum IndicatorType {
  GEO_CHANNEL = 'GEO_CHANNEL',
  READING_CHANNEL = 'READING_CHANNEL',
  PREMIUM_READING_CHANNEL = 'PREMIUM_READING_CHANNEL',
}

@Component({
  standalone: true,
  selector: 'wen-channel-indicator',
  templateUrl: './channel-indicator.component.html',
  imports: [
    WenIconModule,
    NgIf,
    NgClass
  ],
  styleUrls: ['./channel-indicator.component.scss']
})
export class ChannelIndicatorComponent {

  @Input() indicatorType?: IndicatorType;

  constructor(
    private elementRef: ElementRef
  ) {
  }

  get indicatorIcon() {
    switch (this.indicatorType) {
      case IndicatorType.GEO_CHANNEL:
        return 'location';
      case IndicatorType.READING_CHANNEL:
      case IndicatorType.PREMIUM_READING_CHANNEL:
        return 'book';
      default:
        return '';
    }
  }

  get isLarge() {
    return this.elementRef.nativeElement.classList.contains('wen-channel-indicator-large');
  }

  protected readonly IndicatorType = IndicatorType;
}
