import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DialogLayoutModule } from '@portal/wen-components';
import { AdaptiveBackgroundModule } from '../../../../shared/components/adaptive-background/adaptive-background.module';
import { TranslateModule } from '@ngx-translate/core';

export interface ContentErrorModel {
  titleTranslationKey: string;
  descriptionTranslationKey: string;
  imagePath?: string;
  lottiePath?: string;
}

@Component({
  selector: 'wen-content-error',
  templateUrl: './content-error.component.html',
  styleUrls: [ './content-error.component.scss' ],
  imports: [
    CommonModule,
    TranslateModule,
    AdaptiveBackgroundModule,
    DialogLayoutModule,
    LottieModule,
    NgOptimizedImage,
  ],
  standalone: true,
})
export class ContentErrorComponent implements OnInit {
  @Input() contentErrorModel: ContentErrorModel;

  lottieOptions: AnimationOptions;

  ngOnInit() {
    this.lottieOptions = {
      path: this.contentErrorModel.lottiePath,
      autoplay: true,
      loop: true,
    } as AnimationOptions;
  }
}
