
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ReactionContext, SocketIoService } from '@portal/wen-backend-api';
import { filter, first, map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { selectorWithParam } from '../../common/util/selector-with-param';
import { selectChannelById } from '../channel/channel.selectors';
import { RootState } from '../root/public-api';
import { deleteReactions, fetchReactionsForMessages, initReactionListenerAction, sendReactionAction, setMessageReactions, subscribeToGetMessageReactions, updateReactionSummaryForMessage } from './reaction.actions';
import { of } from 'rxjs';

@Injectable()
export class ReactionsEffects implements OnInitEffects {

  sendReaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendReactionAction),
      concatLatestFrom(({ possibleBlockingAncestor }) => {
        if(possibleBlockingAncestor?.type === ReactionContext.CHANNEL) {
          return this.store.pipe(
            selectorWithParam(selectChannelById, possibleBlockingAncestor.id),
            map(c => c.emoji)
          );
        } else {
          return of(true);
        }
      }),
      filter(([_, reactionsEnabled]) => reactionsEnabled),
      map(([{ emojiId, parentId, parentType, broadcastInformation }]) => {
        return this.socketIoService.reactions.send.emit({ emojiId, parentId, parentType, broadcastInformation });
      })
    ), { dispatch: false }
  );

  listenForReactionsForMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subscribeToGetMessageReactions),
      first(),
      switchMap(() => this.socketIoService.reactions.messageReactions.listen.pipe(
        filter((reactionSummary) => reactionSummary && !!Object.keys(reactionSummary)?.length),
        map((response) => setMessageReactions({ response }))
      ))
    )
  );

  listenForReactionSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initReactionListenerAction),
      switchMapTo(this.socketIoService.reactions.send.listen),
      map(reactionUpdate => updateReactionSummaryForMessage({ reactionUpdate }))
    )
  );

  listenForReactionUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initReactionListenerAction),
      switchMapTo(this.socketIoService.reactions.update.listen),
      map(reactionUpdate => updateReactionSummaryForMessage({ reactionUpdate }))
    )
  );

  fetchReactionsForMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchReactionsForMessages),
      filter(({ messageIds, parentIds }) => messageIds?.length > 0 || parentIds?.length > 0),
      tap(({ messageIds, parentIds = [] }) => {
        this.socketIoService.reactions.messageReactions.emit({ messageIds, parentIds });
      }),
      map(({ parentIds }) => deleteReactions({referenceIds: parentIds}))
    ),
  );

  constructor(
    private readonly store: Store<RootState>,
    private readonly actions$: Actions,
    private readonly socketIoService: SocketIoService
  ) { }

  ngrxOnInitEffects(): Action {
    return initReactionListenerAction();
  }
}
