<div class="wen-form">
  <div class="wen-form-group wen-action-selector-entry" *ngIf="isInviteEnabled$ | async">
    <wen-menu-entry (click)="navigateToInviteToNetwork()" [isFlat]="true">
      <wen-icon class="wen-icon-medium" [iconName]="inviteToNetworkConfig.iconName"
        [color]="inviteToNetworkConfig.color" [fillColor]="inviteToNetworkConfig.fillColor" menuEntryIcon>
      </wen-icon>
      <span portal-body-1 menuEntryTitle>{{ inviteToNetworkConfig.titleTranslationKey | translate }}</span>
    </wen-menu-entry>
    <div class="wen-action-selector-entry-infotext" portal-caption>{{ inviteToNetworkConfig.descriptionTranslationKey | translate }}</div>
  </div>
  <div class="wen-form-group wen-action-selector-entry">
    <wen-menu-entry (click)="navigateToInviteToChannel()" [isFlat]="true">
      <wen-icon class="wen-icon-medium" [iconName]="inviteToChannelConfig.iconName"
        [color]="inviteToChannelConfig.color" [fillColor]="inviteToChannelConfig.fillColor" menuEntryIcon>
      </wen-icon>
      <span portal-body-1 menuEntryTitle>{{ inviteToChannelConfig.titleTranslationKey | translate }}</span>
    </wen-menu-entry>
    <div class="wen-action-selector-entry-infotext" portal-caption>{{ inviteToChannelConfig.getDescriptionTranslationKey(isInviteEnabled$ | async) | translate }}</div>
  </div>
  <div class="wen-form-group wen-action-selector-entry">
    <wen-menu-entry (click)="navigateToSendChat()" [isFlat]="true">
      <wen-icon class="wen-icon-medium" [iconName]="sendChatConfig.iconName" [color]="sendChatConfig.color"
        [fillColor]="sendChatConfig.fillColor" menuEntryIcon>
      </wen-icon>
      <span portal-body-1 menuEntryTitle>{{ sendChatConfig.titleTranslationKey | translate }}</span>
    </wen-menu-entry>
    <div class="wen-action-selector-entry-infotext" portal-caption>{{ sendChatConfig.getDescriptionTranslationKey(isInviteEnabled$ | async) | translate }}</div>
  </div>
</div>