import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { ResizeHandler, ResizeHandlerProvider } from '../../../../util/resize-handler';
import { ReactionHandler } from '../../providers/reaction-handler';

@Component({
  standalone: true,
  selector: 'wen-emoji-animation',
  templateUrl: './emoji-animation.component.html',
  styleUrls: ['./emoji-animation.component.scss'],
  imports: [
    LottieModule,
    AsyncPipe,
    NgIf,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiAnimationComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private emojiId = new ReplaySubject<number>(1);
  private resizeHandler: ResizeHandler;

  lottieOptions$: Observable<AnimationOptions>;

  @Input() set id(value: number) {
    this.emojiId.next(value);
  }

  constructor(
    private reactionHandler: ReactionHandler,
    private resizeHandlerProvider: ResizeHandlerProvider,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    // TODO: Remove this when the context menu is reinitialized on each opening
    const nativeElement = this.elementRef.nativeElement as HTMLElement;
    this.resizeHandler = this.resizeHandlerProvider.create(nativeElement);
    this.lottieOptions$ = this.resizeHandler.onResize$.pipe(
      map((size) => size?.height),
      distinctUntilChanged(),
      switchMap((height) => {
        if (height <= 0) {
          return of(null);
        }
        return this.emojiId.pipe(
          distinctUntilChanged(),
          switchMap((value: number) => {
            return this.reactionHandler.getAnimation(value).pipe(
              map((animation: string): AnimationOptions => ({
                animationData: JSON.parse(animation),
                autoplay: true,
                loop: true,
              }))
            );
          })
        );
      }),
      shareReplay(1),
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.resizeHandlerProvider.detach();
  }

}
