
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToRoomEventType } from '@portal/wen-backend-api';
import { ActionMenuItem, ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { filter, first, map } from 'rxjs';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { selectCurrentUserData } from '../../../../../core/store/auth/auth.selectors';
import { requestRedactScheduledChatEvent } from '../../../../../core/store/chat/actions/chat-scheduled-messages.actions';
import { redactChatMessageEvent } from '../../../../../core/store/chat/actions/chat-message.actions';
import { RootState } from '../../../../../core/store/root/public-api';
import { ClientCustomizationProvider } from '../../../../../frame/network/tokens';
import { MessageBoxModel } from '../../../feed/components/we-feed/we-feed-model';

@Injectable()
export class ChatMessageBoxDeleteAction implements ActionMenuItem {

  label: string;
  icon = 'delete';
  iconColor = this.clientCustomizationProvider.getDefaultStyleConfig().errorColor;
  labelColor = this.clientCustomizationProvider.getDefaultStyleConfig().errorColor;

  private currentUserId$ = this.store.pipe(
    select(selectCurrentUserData),
    firstExisty(),
    map(({ userId }) => userId),
  );

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>,
    private overlayManager: OverlayManager,
    private clientCustomizationProvider: ClientCustomizationProvider
  ) {
    this.label = this.translateService.instant('MESSAGE_BOX_CONTEXT_MENU_LABEL_DELETE');
  }

  isVisible$(data: MessageBoxModel) {
    return this.currentUserId$.pipe(
      map(userId => data?.eventType !== ToRoomEventType.AUTO_REPLY && data.authorId === userId)
    );
  }

  execute(messageBox: MessageBoxModel) {
    const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant('DELETE_CONFIRM_DIALOG_TITLE'),
      content: this.translateService.instant('CHAT_MESSAGE_DELETE_CONFIRM_DIALOG'),
      okLabel: this.translateService.instant('CHAT_MESSAGE_DELETE_BUTTON'),
      dismissLabel: this.translateService.instant('CHAT_MESSAGE_DELETE_CANCEL'),
    });

    dialog.afterClosed().pipe(
      first(),
      filter(data => data?.result === 'ok')
    ).subscribe(() => {
      if (!messageBox.scheduled) {
        this.store.dispatch(redactChatMessageEvent({
          eventId: messageBox.referenceId
        }));
      } else {
        this.store.dispatch(requestRedactScheduledChatEvent({
          eventId: messageBox.referenceId
        }));
      }
    });
  }

}
