<div class="wen-channel-message-detail-content">
  <ng-container *ngIf="errorState; else messagesView">
    <div class="wen-channel-message-detail-errors-wrapper">
      <wen-message-error [errorType]="errorState.error" [channelData]="errorState.channelData"></wen-message-error>
    </div>
  </ng-container>
  <ng-template #messagesView>
    <wen-channel-messages-view [flowDirection]="'down'" [disableContextMenu]="true" [disableScrollToBottomButton]="true">
    </wen-channel-messages-view>
  </ng-template>
</div>
<wen-comment-message-send-field *ngIf="showCommentButton$ | async" [parentId]="messageId$ | async" [wen-test-id]="'comment-send-area'"></wen-comment-message-send-field>

