<ng-container *ngIf="mediaViewParams$ | async as viewParams">
  <div class="wen-media-wrapper" [thumbnail-evaluator]="viewParams">
    <ng-container [ngSwitch]="viewParams.embedType">
      <wen-embed-image *ngSwitchCase="mediaType.PICTURE" [config]="viewParams" (mediaClick)="onMediaClick($event)"></wen-embed-image>
      <wen-embed-video *ngSwitchCase="mediaType.VIDEO" [config]="viewParams" (mediaClick)="onMediaClick($event)"></wen-embed-video>
      <wen-embed-audio *ngSwitchCase="mediaType.AUDIO" [config]="viewParams"></wen-embed-audio>
      <wen-embed-upload *ngSwitchCase="mediaType.UPLOAD" [config]="viewParams"></wen-embed-upload>
      <wen-embed-upload *ngSwitchCase="mediaType.PROCESS" [config]="viewParams"></wen-embed-upload>
      <wen-embed-error *ngSwitchCase="mediaType.ERROR" [config]="viewParams"></wen-embed-error>
      <wen-embed-file *ngSwitchCase="mediaType.DOCUMENT" [config]="viewParams"></wen-embed-file>
    </ng-container>
  </div>
</ng-container>
