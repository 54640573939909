<div class="wen-section-header">
  <ng-content select="section-header-title, [section-header-title]"></ng-content>
  <ng-content select="section-header-action, [section-header-action]"></ng-content>
</div>
<div class="wen-section-content" #sectionContent [wen-test-id]="'explore-section'">
  <ng-container *ngIf="isScrollersVisible$ | async">
    <ng-container *ngIf="scrollVisibility$ | async as visibility">
      <wen-scroll-to-button *ngIf="visibility.left" class="left" (scrolledTo)="scrollLeft()"></wen-scroll-to-button>
      <wen-scroll-to-button *ngIf="visibility.right" class="right" (scrolledTo)="scrollRight()"></wen-scroll-to-button>
    </ng-container>
  </ng-container>
  <div class="wen-section-content-wrapper" #contentWrapper>
    <ng-content select="section-content, [section-content]"></ng-content>
  </div>
</div>