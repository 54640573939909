import { createAction, props } from '@ngrx/store';
import { HeaderCommandType } from './models/HeaderCommand';
import { HeaderData } from './models/HeaderData';

export const updateHeaderData = createAction(
  '[Header] Update header data',
  props<{ headerData: HeaderData }>()
);

export const headerCommandClicked = createAction(
  '[Header] Header command clicked',
  props<{ commandType: HeaderCommandType }>()
);

export const doBackNavigation = createAction(
  '[Header] Back navigation requested'
);

export const upsertHeaderData = createAction(
  '[Header] Upsert the header data',
  props<{ headerData: Partial<HeaderData> }>()
);

export const updateSearchbarActive = createAction(
  '[Header] Updates the active state of the searchbar',
  props<{ active: boolean }>()
);
