<ng-container *ngIf="section$ | async; let section" [ngSwitch]="config?.context">
  <wen-channel-section
    *ngSwitchCase="DataContext.CHANNEL"
    [section]="section"
    (showAll)="navigateToChannelDiscovery($event)">
  </wen-channel-section>
  <wen-app-section
    *ngSwitchCase="DataContext.APP"
    [section]="section"
    (showAll)="navigateToAppDiscovery($event)">
  </wen-app-section>
  <wen-event-section
    *ngSwitchCase="DataContext.EVENT"
    [section]="section"
    (showAll)="navigateToEventDiscovery($event)">
  </wen-event-section>
</ng-container>
