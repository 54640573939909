import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageSendFieldDataSource } from '../../providers/message-send-field-data-source';
import { map, Observable, Subject } from 'rxjs';
import { Quote } from '../../../../../core/store/common/models';
import { slideInSlideOutTopAnimation } from '../../../../../frame/components/wen-widget/animations/slide-in.animation';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'wen-quote-embed-preview',
  templateUrl: './quote-embed-preview.component.html',
  styleUrls: ['./quote-embed-preview.component.scss'],
  animations: [slideInSlideOutTopAnimation]
})
export class QuoteEmbedPreviewComponent {
  @Input() commentAuthor: string;
  @Input() comment: string;
  @Output() isQuoting = new EventEmitter<boolean>();
  public quote$: Observable<Quote> = this.dataSource.draftMessage$.pipe(
    distinctUntilChanged(),
    map(message => {
      this.isQuoting.emit(!!message?.quote);
      return message?.quote;
    })
  );

  protected readonly onDestroy$ = new Subject<void>();

  constructor(private readonly dataSource: MessageSendFieldDataSource) { }

  public closeQuotePreview(): void {
    this.dataSource.updateDraftMessage({ quote: undefined});
  }
}
