import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { map, switchMap } from 'rxjs';
import { firstExisty } from '../../../../../../core/common/operators/first-existy';
import { FilterId } from '../../../../../../core/common/types/filter-id';
import { selectorWithParam } from '../../../../../../core/common/util/selector-with-param';
import { selectEventCategories } from '../../../../../../core/store/events/event.selectors';
import { selectActiveFilterById } from '../../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../../core/store/root/public-api';
import { FilterSelectorOpener } from '../../../../../../shared/components/filter-view/components/filter-selector/filter-selector-opener';
import { createFilterableFromCategory } from '../../../../../../shared/components/filter-view/models/category';
import { Filterable } from '../../../../../../shared/components/filter-view/models/filter-item';

@Injectable()
export class EventCategoryFilterDataSource {

  currentActiveEventCategoryFilter$ = this.store.pipe(
    selectorWithParam(selectActiveFilterById, FilterEntityType.EVENT_LISTS, FilterId.EVENT_CATEGORIES),
    smartDistinctUntilChanged()
  );

  get fallbackAllCategoryFilter(): Filterable {
    return { id: null, name: this.translateService.instant('EVENT_FILTER_CATEGORY_SELECTION_OPTION_ALL_CATEGORY'), filterString: null };
  }

  constructor(
    private store: Store<RootState>,
    private translateService: TranslateService,
    private filterSelectorOpener: FilterSelectorOpener,
  ) {
  }

  private loadCategories() {
    return this.store.pipe(
      select(selectEventCategories),
      firstExisty(),
      map(categories => categories.map(c => createFilterableFromCategory('categories', c))),
      map(filters => filters.sort((f1, f2) => f1.name.localeCompare(f2.name)))
    );
  }

  openFilterSelector(activeCategory: Filterable) {
    return this.loadCategories().pipe(
      switchMap((possibleValues) => {
        const dialogData = {
          visibleFilters: [this.fallbackAllCategoryFilter, ...possibleValues],
          activeFilterIds: [activeCategory?.id || this.fallbackAllCategoryFilter.id],
        };
        return this.filterSelectorOpener.openFilterSelector(dialogData);
      })
    );
  }

}
