import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { first, map, switchMap } from 'rxjs';
import { FilterId } from '../../../../../../core/common/types/filter-id';
import { selectorWithParam } from '../../../../../../core/common/util/selector-with-param';
import { selectActiveFilterById } from '../../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../../core/store/root/public-api';
import { FilterSelectorOpener } from '../../../../../../shared/components/filter-view/components/filter-selector/filter-selector-opener';
import { createFilterableFromCategory } from '../../../../../../shared/components/filter-view/models/category';
import { Filterable } from '../../../../../../shared/components/filter-view/models/filter-item';

@Injectable()
export class AppCategoryFilterDataSource {

  currentActiveAppCategory$ = this.store.pipe(
    selectorWithParam(selectActiveFilterById, FilterEntityType.APP_LISTS, FilterId.APP_CATEGORIES),
    smartDistinctUntilChanged()
  );

  get fallbackAllCategoryFilter(): Filterable {
    return { id: null, name: this.translateService.instant('FILTER_CATEGORIES_SELECTOR') };
  }

  private get fallbackAllCategoryFilterForDialog(): Filterable {
    return { id: null, name: this.translateService.instant('FILTER_SELECTION_OPTION_ALL_CATEGORY'), filterString: null };
  }

  constructor(
    private socketIoService: SocketIoService,
    private translateService: TranslateService,
    private filterSelectorOpener: FilterSelectorOpener,
    private store: Store<RootState>
  ) { }

  private loadCategories() {
    return this.socketIoService.app.getCategories.acknowledgement$().pipe(
      first(),
      map(categories => categories.map(c => createFilterableFromCategory('categories', c)))
    );
  }

  openFilterSelector(activeCategory: Filterable) {
    return this.loadCategories().pipe(
      switchMap((possibleValues) => {
        const dialogData = {
          visibleFilters: [this.fallbackAllCategoryFilterForDialog, ...possibleValues],
          activeFilterIds: [activeCategory?.id || this.fallbackAllCategoryFilterForDialog.id],
        };
        return this.filterSelectorOpener.openFilterSelector(dialogData);
      })
    );
  }

}
