<div class="wen-form" cdkScrollable>
  <ng-container *ngIf="roomDetails$ | async; let roomDetails">
    <ng-container *ngIf="headerData$ | async; let headerData">
      <wen-detail-header [model]="headerData">
        <div wen-detail-header-title>
          <span portal-title>{{ headerData.title }}</span>
        </div>
        <button *ngIf="isAdmin$ | async" wen-detail-header-quick-actions mat-mini-fab color="primary"
          class="mat-elevation-z0" (click)="editRoom(roomDetails.id)" [wen-test-id]="'wen-chat-detail-edit-button'">
          <wen-icon iconName="edit"></wen-icon>
        </button>
        <button *ngIf="isAdmin$ | async" wen-detail-header-quick-actions mat-mini-fab color="primary"
          class="mat-elevation-z0" (click)="addMembers(roomDetails.id)"
          [disabled]="roomDetails.memberCount >= maxNumberOfUsers">
          <wen-icon class="wen-icon-big" iconName="add_user"></wen-icon>
        </button>
        <wen-mute-unmute-chat-button wen-detail-header-quick-actions [chat]="roomDetails" >
        </wen-mute-unmute-chat-button>
        <button wen-detail-header-quick-actions mat-mini-fab color="primary"
          class="mat-elevation-z0" (click)="leaveRoom(roomDetails.id)">
          <wen-icon iconName="logout"></wen-icon>
        </button>
      </wen-detail-header>
    </ng-container>
    <div *ngIf="roomDetails.description" class="wen-form-group">
      <div class="wen-form-field-wrapper wen-chat-detail-field-text">
        <div class="wen-form-field-label" portal-caption>{{ 'LABEL_DESCRIPTION' | translate }}</div>
        <div class="wen-form-field" portal-body>{{ roomDetails.description }}</div>
      </div>
    </div>
    <div class="wen-form-group wen-single-group">
      <wen-chat-detail-user-list [isAdmin]="isAdmin$ | async" [roomId]="roomDetails.id" [memberCount]="roomDetails.memberCount"></wen-chat-detail-user-list>
    </div>
  </ng-container>
</div>
