import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { WenIconModule } from '../wen-icon/wen-icon.module';

@Component({
  selector: 'wen-scroll-to-button',
  templateUrl: './scroll-to-button.component.html',
  imports: [NgIf, WenIconModule],
  styleUrls: ['./scroll-to-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true
})
export class ScrollToButtonComponent {

  @HostBinding('class.wen-scroll-to-button') className = true;

  @Input() notificationCount: number;
  @Output() scrolledTo = new EventEmitter<void>();

  notificationCount$: Observable<number>;

  onScrollTo() {
    this.scrolledTo.next();
  }

}
