<ng-container *ngIf="availableEmojis$ | async as availableEmojis">
  <ng-container *ngIf="reactionData$ | async as reactionData; else reactionPlaceholder">
    <div class="emojis-wrapper">
      <ng-container *ngFor="let reaction of reactionData.reactions; trackBy: trackByFn">
        <div class="wen-emoji-wrapper" (click)="onAddReaction(reactionData.userSelection, reaction.emojiId)">
          <div [id]="emojiHtmlIds[reaction.emojiId]" class="wen-emoji-content">
            <wen-emoji [id]="reaction.emojiId"
                       [selected]="reaction.emojiId === reactionData.userSelection"
                       [wen-test-id]="'wen-emoji-' + reaction.emojiId">
            </wen-emoji>
          </div>
          <span class="emoji-counter" [wen-test-id]="'wen-emoji-counter-' + reaction.emojiId" portal-subheading-1>{{ reaction.count | thousandFormat }}</span>
        </div>
      </ng-container>
      <div class="wen-emoji-wrapper" [context-menu-trigger]="reactionMenu">
        <wen-icon iconName="add_reaction" class="wen-add-emoji" [wen-test-id]="'add-reaction-button'"/>
      </div>
    </div>
  </ng-container>

  <ng-template #reactionPlaceholder>
    <ng-container *ngIf="showPlaceholder">
      <ng-container *ngFor="let id of availableEmojis">
        <div class="wen-emoji-wrapper wen-emoji-wrapper-placeholder" (click)="onNewReaction(id)">
          <wen-emoji [id]="id" [wen-test-id]="'wen-emoji-' + id">
          </wen-emoji>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
