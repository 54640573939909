import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ColorizeModule, MediaPreviewModule, WenComponentsModule, WenIconModule, WenTestIdModule } from '@portal/wen-components';
import { VirtualKeyboardModule } from '../../directives/virtual-keyboard/virtual-keyboard.module';
import { IosUtilsModule } from '../../ios-util/ios-util.module';
import { QuoteModule } from '../quote/quote.module';
import { ThumbnailImageModule } from '../thumbnail-image/thumbnail-image.module';
import { MediaEmbedPreviewComponent } from './components/media-embed-preview/media-embed-preview.component';
import { MessageDiscardEditButtonComponent } from './components/message-discard-edit-button/message-discard-edit-button.component';
import { MessageSchedulerComponent } from './components/message-scheduler/message-scheduler.component';
import { TimePickerModule } from './components/message-scheduler/time-picker/time-picker.module';
import { MessageSendFieldComponent } from './components/message-send-field.component';
import { QuoteEmbedPreviewComponent } from './components/quote-embed-preview/quote-embed-preview.component';
import { PollPreviewComponent } from './components/poll-preview/poll-preview.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ThumbnailImageModule,
    MatInputModule,
    WenIconModule,
    TimePickerModule,
    IosUtilsModule,
    VirtualKeyboardModule,
    MediaPreviewModule,
    WenTestIdModule,
    ColorizeModule,
    WenComponentsModule,
    QuoteModule,
  ],
  declarations: [
    MessageSendFieldComponent,
    MediaEmbedPreviewComponent,
    MessageSchedulerComponent,
    MessageDiscardEditButtonComponent,
    QuoteEmbedPreviewComponent,
    PollPreviewComponent
  ],
  exports: [
    MessageSendFieldComponent,
    MediaEmbedPreviewComponent,
    MessageSchedulerComponent,
    MessageDiscardEditButtonComponent,
    QuoteEmbedPreviewComponent,
    PollPreviewComponent
  ]
})
export class MessageSendFieldModule { }
