import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { PopupEventHandler, PopupFlowEvent } from '../../../../core/services/util/popup-event-handler';

@Component({
  selector: 'wen-silent-logout',
  template: `
    <iframe *ngIf="logoutInProgress$ | async" [src]="logoutUrl" hidden></iframe>
  `
})
export class SilentLogoutComponent implements OnInit, OnDestroy {

  logoutUrl = this.sanitizer.bypassSecurityTrustResourceUrl(location.origin + '/oauth/logout');
  logoutInProgress$ = new BehaviorSubject(false);

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly popupEventHandler: PopupEventHandler
  ) { }

  private onDestroy$ = new Subject<void>();

  ngOnInit() {
    this.popupEventHandler.listenToPopupEvents(PopupFlowEvent.LOGGED_OUT).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.logoutInProgress$.next(false);
    });
  }

  silentLogout() {
    this.logoutInProgress$.next(true);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
