<div class="wen-detail-header">
  <div class="wen-header-image-wrapper">
    <div *ngIf="model.pendingImage && allowLoadingIndicator; else loadedImageView" class="wen-detail-header-pending-image-wrapper">
      <ng-content select="[wen-detail-header-pending-image]"></ng-content>
    </div>
    <ng-template #loadedImageView>
      <div *ngIf="showImage; else defaultImageView" class="wen-detail-header-cover-wrapper">
        <img class="wen-detail-header-cover-image" [src]="model.imageUrl | wenImageUrlTransform: (coverImageLoadedSize$ | async)"
             (load)="onImageLoaded()" (error)="onImageError()" [style.object-fit]="coverImageFitStyle" />
      </div>
    </ng-template>
  </div>
  <ng-template #defaultImageView>
    <wen-circle-initial-view class="wen-detail-header-default-image" [text]="model.title" [shape]="coverImageStyle">
    </wen-circle-initial-view>
  </ng-template>
  <div class="wen-detail-header-subheader" [ngClass]="{ 'wen-default-subheader-flow': !showImage }">
    <div #summaryWrapper class="wen-detail-header-summary">
      <div class="wen-detail-header-title">
        <ng-content select="[wen-detail-header-title]"></ng-content>
        <ng-content select="[wen-detail-header-hint]"></ng-content>
      </div>
      <div *ngIf="model.description" portal-body-2 class="wen-detail-header-description"> {{ model.description }}</div>
    </div>
    <div class="wen-detail-header-quick-actions">
      <ng-content select="[wen-detail-header-quick-actions]"></ng-content>
    </div>
  </div>
  <div class="wen-detail-header-additional-subheader" *ngIf="headerAdditionalContent">
    <ng-content select="[wen-detail-header-additional-content], wen-detail-header-additional-content"></ng-content>
  </div>
</div>
