<form wenFormStore class="wen-form" [formGroup]="userLanguageForm" (keyup.enter)="saveSettings()">
    <div class="wen-form-group" *ngIf="selectionLanguageModel">
        <div class="wen-form-field-wrapper">
            <div class="wen-form-field-label" portal-caption>{{'USER_PROFILE_SELECT_LANGUAGE' | translate}}</div>
            <wen-selection-list
                (selectionChanged)="onSelectionChanged($event)"
                [selectionModel]="selectionLanguageModel"
                [selectionItems]="selectionLanguageItems"
                [selectionOptionPosition]="'postfix'"
                [selectionOptionBorderType]="'borderless'"
                [allowEmptyList]="false">
            </wen-selection-list>
        </div>
    </div>
</form>
