<wen-dialog-layout [headerGravity]="'top'">
  <wen-dialog-layout-header [inline]="true">
    <button headerLeft portal-button inlined (click)="onDismiss()">
      <span portal-title>{{ "REGISTRATION_BACK_LABEL" | translate }}</span>
    </button>
    <button headerRight portal-button inlined (click)="onDismiss()">
      <span portal-title>{{ "REGISTRATION_CONFIRM_LABEL" | translate }}</span>
    </button>
  </wen-dialog-layout-header>
  <div class="wen-phone-number-area-picker-content" dialogLayoutContent>
    <div *ngFor="let country of availableCountries" class="wen-phone-number-area-picker-item"
      (click)="selectCountry(country)"
      [class.wen-phone-number-area-picker-item-info-selected]="country.areaCode === selected.areaCode">
      <div class="wen-phone-number-area-picker-item-info">
        <span portal-title>{{ country.name }}</span>
        <span portal-subheading-1>{{ country.countrySign }}</span>
      </div>
      <div portal-title class="wen-phone-number-area-picker-item-areacode">{{ country.areaCode }}</div>
    </div>
  </div>
</wen-dialog-layout>