<ng-container *ngIf="!commandComponent; else componentCommand">
  <div class="wen-header-command-button wen-header-command-button-{{getClassSuffix()}}" (click)="commandClicked()" [wen-test-id]="'wen-header-command-button-' + getClassSuffix()">
    <wen-icon [class.wen-icon-disabled]="isCommandDisabled" *ngIf="getIcon()" [iconName]="getIcon()"></wen-icon>
    <span class="wen-header-command-button-label">{{ getTitle() | translate }}</span>
  </div>
</ng-container>
<ng-template #componentCommand>
  <div (click)="commandClicked()" class="wen-header-command-button" [wen-test-id]="'wen-header-command-button-' + getClassSuffix()">
    <ng-template [cdkPortalOutlet]="commandComponent" (attached)="onComponentAttached($event)"></ng-template>
  </div>
</ng-template>