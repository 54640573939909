<ng-container *ngIf="restrictions">
  <ng-container *ngIf="requestOnly; else subscribe">
    <wen-interact-button *ngIf="pendingRequest$ | async; else notPending" (click)="withdrawJoinRequest()">
      <wen-icon wen-interact-button-icon class="wen-icon-small" [iconName]="'lock'"></wen-icon>
      {{ "CHANNEL_JOIN_REQUEST_WITHDRAW_BUTTON_LABEL" | translate }}
    </wen-interact-button>
  </ng-container>
</ng-container>

<ng-template #subscribe>
  <wen-interact-button (click)="subscribeToChannel()" [wen-test-id]="'channel-subscribe-button'">
    <wen-icon wen-interact-button-icon class="wen-icon-small" *ngIf="restrictions.length" [iconName]="'lock'"></wen-icon>
    {{ "CHANNEL_SUBSCRIBE_BUTTON_LABEL" | translate }}
  </wen-interact-button>
</ng-template>

<ng-template #notPending>
  <wen-interact-button (click)="requestAccessToChannel()">
    <wen-icon wen-interact-button-icon class="wen-icon-small" [iconName]="'lock'"></wen-icon>
    {{ "CHANNEL_JOIN_REQUEST_SEND_BUTTON_LABEL" | translate }}
  </wen-interact-button>
</ng-template>