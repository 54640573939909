<form wenFormStore class="wen-form" *ngIf="channel$ | async as channel" [formGroup]="channelInteractionSettingsFormGroup" [wen-test-id]="'wen-channel-interaction-settings-form'">
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper">
      <div portal-caption class="wen-form-field-label">{{ "CHANNEL_INTERACTION_SETTING_FIELD_LABEL" | translate }}</div>
      <div class="wen-form-field wen-toggle-field" [wen-test-id]="'emoji-toggle'">
        <div>{{ "CHANNEL_EMOJI_INTERACTION_TOGGLE_LABEL" | translate }}</div>
        <mat-slide-toggle color="primary" [formControlName]="'emoji'"
        ></mat-slide-toggle>
      </div>
      <div *ngIf="featureEnablementService.featureFlagMethods.isEnableCommentReaction()" class="wen-form-field wen-toggle-field" [wen-test-id]="'comment-toggle'">
        <div>{{ "CHANNEL_COMMENT_INTERACTION_TOGGLE_LABEL" | translate }}</div>
        <mat-slide-toggle color="primary" [formControlName]="'comment'"
        ></mat-slide-toggle>
      </div>
    </div>
  </div>
</form>
