<div class="wen-chat-scheduled-messages-content">
  <wen-chat-messages-view [flowDirection]="'down'" [disableScrollToBottomButton]="true" [disableEmojiReaction]="true">
    <wen-we-feed-empty-placeholder
      [text]="'EMPTY_CHAT_SCHEDULED_MESSAGES_LABEL' | translate"
      [imageUrl]="'/assets/wen-widget/image/pic-no-Scheduled_messages.svg'">
    </wen-we-feed-empty-placeholder>
  </wen-chat-messages-view>
</div>
<div class="wen-chat-schedules-bottom-controls" *ngIf="isMessageInputVisible$ | async">
  <div class="wen-chat-schedules-edit-wrapper">
    <wen-chat-message-send-field (focused)="onFocusSendField()"></wen-chat-message-send-field>
  </div>
</div>