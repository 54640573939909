<form wenFormStore class="wen-form" [formGroup]="autoReplyFormGroup">
  <div class="wen-form-group wen-auto-reply-edit-wrapper">
    <div class="wen-form-toggle-wrapper">
      <p>{{'USER_PROFILE_AUTO_REPLY' | translate}}</p>
      <mat-slide-toggle color="primary" class="wen-poll-toggle" formControlName="enabled"></mat-slide-toggle>
    </div>
    <div portal-caption class="wen-form-field-label wen-auto-reply-info">
      {{ 'USER_PROFILE_AUTO_REPLY_INFO' | translate }}
    </div>
    <div class="wen-form-field-wrapper wen-form-field-textarea" *ngIf="isEnabled$ | async">
      <mat-form-field>
        <mat-label>{{ 'USER_PROFILE_AUTO_REPLY_INPUT_LABEL' | translate }}</mat-label>
        <div class="wen-textarea-wrapper">
          <textarea
            class="wen-auto-reply-textarea"
            [formControlName]="'replyMessage'"
            maxlength="{{ maximumReplyLength }}"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="8"
            wenIosKeyboardStickyTarget>
          </textarea>
          <div class="icon-wrapper" *ngIf="itemCount$ | async">
            <wen-icon wen-primary-color [iconName]="'close'"(click)="clearInput()"></wen-icon>
          </div>
      </div>
      <mat-error portal-caption>{{ "USER_PROFILE_AUTO_REPLY_TEXTAREA_ERROR" | translate }}</mat-error>
      <mat-hint portal-caption align="end" class="wen-description-item-count" [align]  *ngIf="itemCount$ | async as itemCount">
        {{ itemCount }}/{{ maximumReplyLength }}
      </mat-hint>
      </mat-form-field>
    </div>
  </div>
</form>
