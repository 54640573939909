import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { filter, Observable, tap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ReactionHandler } from './providers/reaction-handler';
import { ReactionContext, ReactionCount, ReactionSummary } from '@portal/wen-backend-api';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { AnimationControls, animate } from 'motion';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type DisplayableReactions = {
  userSelection: number;
  reactions: ReactionCount[];
};

@Component({
  selector: 'wen-emoji-reactions',
  templateUrl: './emoji-reactions.component.html',
  styleUrls: ['./emoji-reactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiReactionsComponent implements OnInit {
  reactionData$: Observable<DisplayableReactions>;
  @HostBinding('class.disabled') get isComponentDisabled() {
    return this.disabled;
  }
  availableEmojis$: Observable<number[]>;
  emojiHtmlIds: Map<number, string>;

  @Input() disabled: boolean;
  @Input() showPlaceholder = false;
  @Input() referenceId: string;
  @Input() reactionMenu: ContextMenuComponent;
  @Input() reactionContext: ReactionContext;

  private animation: AnimationControls;
  private destroyRef = inject(DestroyRef);

  constructor(private reactionHandler: ReactionHandler) { }

  ngOnInit(): void {
    this.availableEmojis$ = this.reactionHandler.getAvailableEmojiIds().pipe(
      tap(availableEmojiIds => {
        this.emojiHtmlIds = new Map<number, string>();
        for (const emojiId of availableEmojiIds) {
          this.emojiHtmlIds[emojiId] = `emoji${emojiId}-${this.referenceId}`;
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    );

    this.reactionData$ = this.reactionHandler.getReactionSummaryFor(this.referenceId).pipe(
      map((reactionSummary: ReactionSummary) => {
        if (reactionSummary?.emojiCounts.length) {
          const { currentUsersSelection, emojiCounts } = reactionSummary;
          const sortedReactions = this.sortReactions(emojiCounts);
          return {
            userSelection: currentUsersSelection,
            reactions: sortedReactions,
          };
        }
        return null;
      }),
      startWith(null),
      takeUntilDestroyed(this.destroyRef)
    );

    this.reactionHandler.reactionsFromUser$.pipe(
      filter(({ referenceId }) => referenceId === this.referenceId),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(({ emojiId, referenceId }) => {
      setTimeout(
        () => this.animateReaction(this.emojiHtmlIds[emojiId]),
        0
      );
    });
  }

  trackByFn(index: number, item: ReactionCount) {
    return item.emojiId;
  }

  private sortReactions(reactionCounts: ReactionCount[]): ReactionCount[] {
    if (reactionCounts.length < 2) {
      return reactionCounts;
    }
    return reactionCounts
      .slice()
      .sort((a: ReactionCount, b: ReactionCount) => {
        if (a.count !== b.count) {
          return b.count - a.count;
        }

        const bMilliseconds = new Date(b.lastAddedDateTime).getTime();
        const aMilliseconds = new Date(a.lastAddedDateTime).getTime();

        return bMilliseconds - aMilliseconds;
      });
  }

  onAddReaction(selection: number, emojiId: number) {
    this.sendReaction((selection === emojiId) ? null : emojiId);
  }

  onNewReaction(emojiId: number) {
    this.sendReaction(emojiId);
  }

  sendReaction(emojiId: number | null) {
    this.reactionHandler.sendUserReaction(emojiId, this.referenceId, this.reactionContext);
  }

  private animateReaction(targetElementId: string): void {
    if (!targetElementId || !document.getElementById(targetElementId)) {
      return;
    }

    this.animation?.stop();
    this.animation = animate(
      '#' + targetElementId,
      { scale: [1, 1.5, 0.9, 1] },
      {
        easing: [0.38636363636363635, -0.022727272727272707, 0.6439393939393939, 0.6818181818181819],
        duration: 0.8
      }
    );

    const currentControls = this.animation;
    this.animation.finished.then(() => {
      if (this.animation === currentControls) {
        this.animation = null;
      }
    });
  }
}
