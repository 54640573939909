<ng-container *ngIf="hasValue">
  <div class="wen-media-preview-wrapper">
    <div class="wen-media-preview-image-wrapper">
      <div class="wen-media-preview" [ngClass]="{'wen-media-icon': mediaIconName}">
        <ng-container *ngIf="mediaSrc">
          <img [src]="mediaSrc" (error)="onImageError()"/>
        </ng-container>
        <ng-container *ngIf="mediaIconName">
          <wen-icon [class.wen-icon-huge]="fitStyle" [iconName]="mediaIconName"></wen-icon>
        </ng-container>
      </div>
      <wen-icon wen-primary-background-color [class.wen-icon-huge]="fitStyle" class="wen-icon-medium wen-media-preview-remove" iconName="close"
      (click)="emitDismiss()"></wen-icon>
    </div>
    <div *ngIf="!mediaError" class="wen-media-preview-details">
      <div [wen-middle-ellipsize]="7" [text]="mediaFileName"></div>
      <div>{{ mediaFileSize | filesize }}</div>
    </div>
  </div>
</ng-container>
