<div class="wen-navigate-to-channel-button-content" (click)="onClick()">
  <wen-circle-image-view [imageUrl]="image"
    [placeholderText]="description" [fontSize]="18"></wen-circle-image-view>
  <div class="wen-navigate-to-channel-button-text">
    <h4 class="wen-navigate-to-channel-button-text-header">{{ 'CHANNEL_FEATURED_NAVIGATE_TO_CHANNEL_BUTTON_TITLE' |
      translate }}</h4>
    <h3 class="wen-navigate-to-channel-button-text-description">{{ description }}</h3>
  </div>
  <button class="wen-navigate-to-channel-button-button" portal-button inlined>
    <h2>{{ 'CHANNEL_FEATURED_NAVIGATE_TO_CHANNEL_BUTTON_LABEL' | translate }}</h2>
  </button>
</div>