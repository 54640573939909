<ng-container *ngIf="linkViewModel">
  <wen-thumbnail-image
    *ngIf="linkViewModel.thumbnail; let thumbnail"
    [thumbnail]="thumbnail"
    (clicked)="openUrl()"
    [roundedEdges]="true"
  >
  </wen-thumbnail-image>
  <div class="wen-embedded-link-text-wrapper">
    <div portal-title class="wen-embed-link-title">{{ linkViewModel.title }}</div>
    <div *ngIf="linkViewModel.description" portal-subheading-1 class="wen-embed-link-description">{{ linkViewModel.description }}</div>
  </div>
  <div class="wen-embedded-link-url-wrapper">
    <div portal-body-2 class="wen-embed-link-url" (click)="openUrl()">
      {{ linkOpenerTranslationKey | translate }}
    </div>
  </div>
</ng-container>
