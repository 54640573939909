import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { emailValidator } from '../../../../../core/common/util/field-validators';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectUserProfile } from '../../../../../core/store/user/user.selectors';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';

@Component({
  selector: 'wen-email-change-form',
  templateUrl: './email-change-form.component.html',
  styleUrls: ['./email-change-form.component.scss']
})
export class EmailChangeFormComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  emailChangeFormGroup = new FormGroup({
    email: new FormControl('', {
      validators: [emailValidator()],
    }),
  });

  get emailControl() {
    return this.emailChangeFormGroup.controls.email;
  }

  get emailValue() {
    return this.emailControl.value;
  }

  constructor(
    private readonly store: Store<RootState>,
    private readonly formStoreService: FormStoreService,
  ) { }

  ngOnInit(): void {
    this.store.pipe(
      select(selectUserProfile),
      firstExisty(),
      takeUntil(this.onDestroy$)
    ).subscribe(data => {
      this.formStoreService.initializeForm(data);
    });
  }

  clearInput() {
    this.emailControl.markAsDirty();
    this.emailControl.setValue('');
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
