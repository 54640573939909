import { AfterViewInit, Directive, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { ContextMenuComponent } from '../../../components/context-menu/context-menu.component';
import { EventListenerManager } from '../../../util/event-listener-manager';
import { ContextMenuOverlayService } from './context-menu-overlay.service';

export type OpeningMode = 'click' | 'tap' | 'press';

const isDomEvent = (event: Event | HammerInput): event is Event => {
  return Boolean((event as Event).stopPropagation);
};

@Directive({
  selector: 'context-menu-trigger, [context-menu-trigger]',
  standalone: true,
})
export class ContextMenuTriggerDirective implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  private eventListenerManager = new EventListenerManager();

  @Input() contextMenuTriggerDisabled: boolean;
  @Input('context-menu-trigger') contextMenu: ContextMenuComponent;

  @Input() openMode: OpeningMode = 'click';

  constructor(
    private viewContainerRef: ViewContainerRef,
    private overlayService: ContextMenuOverlayService,
    private eventManager: EventManager
  ) { }

  ngAfterViewInit() {
    this.setupContextMenuOpening();
  }

  private showOverlay() {
    if (this.contextMenu) {
      this.overlayService.openOverlay(this.contextMenu, this.viewContainerRef);
    }
  }

  private setupContextMenuOpening() {
    this.eventListenerManager.addEvent = this.eventManager.addEventListener(
      this.viewContainerRef.element.nativeElement,
      this.openMode,
      (event: Event | HammerInput) => {
        if (event.type === this.openMode && !this.contextMenuTriggerDisabled) {
          this.showOverlay();
          if (isDomEvent(event)) {
            event.stopPropagation();
          }
        }
      }
    );
    // with the tap event to avoid the ghost click...
    this.eventListenerManager.addEvent = this.eventManager.addEventListener(
      this.viewContainerRef.element.nativeElement,
      'touchend',
      (ev: Event) => {
        if (ev && this.openMode === 'tap' && !this.contextMenuTriggerDisabled) {
          ev.preventDefault();
        }
      }
    );
  }

  ngOnDestroy() {
    this.eventListenerManager.detachAll();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


}
