<div *ngIf="prefixTranslation" class="wen-chip-input-search-prefix">
  <span>{{ prefixTranslation | translate }}</span>
</div>
<div class="wen-form-field-wrapper">
  <mat-form-field class="wen-form-field-white">
    <mat-chip-grid #chipGrid class="wen-chip-grid" (change)="onChipGridChange()">
      <div class="mat-chip-list-wrapper">
        <mat-chip-row class="wen-chip-row" *ngFor="let item of selectedItems"
          [value]="item"
          (removed)="onItemRemoval(item)">
          <img *ngIf="item.icon" class="wen-chip-avatar" matChipAvatar [src]="item.icon" alt="item image" />
          <span class="wen-chip-label">{{item.text}}</span>
          <wen-icon iconName="close" matChipRemove class="wen-icon-medium wen-chip-button"></wen-icon>
        </mat-chip-row>
        <input #chipInput
          [disabled]="itemCount >= maxNumberOfSelectableItems"
          [matChipInputFor]="chipGrid"
          [placeholder]="placeholder$ | async | translate"
          (input)="onSearchChange($event.target.value)"
          wenVkInput [wenVkInputKeepFocused]="keepFocused"
          wenIosKeyboardLockTarget
          autocomplete="off"
        />
      </div>
    </mat-chip-grid>
    <div *ngIf="itemCount > 0" class="wen-selected-item-count" portal-subheading-1>
      {{itemCount}} / {{maxNumberOfSelectableItems}}
    </div>
  </mat-form-field>
</div>
